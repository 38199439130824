import RestClient from '../RestClient';

export const fetchCurrentUserInfo = async () => {
  const res = await RestClient.get('/api/security/current_user_info');
  if (res?.status === 200 && res?.data) {
    return res.data;
  } else {
    console.error('Failed to fetch user info:', res?.message);
    return null;
  }
};