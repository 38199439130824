import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchCurrentUserInfo as fetchRealUserInfo } from "../services/auth/AuthService";
import { fetchCurrentUserInfo as fetchMockUserInfo } from "../services/auth/MockAuthService";
import { CircularProgress } from "@mui/material";

const RoleContext = createContext();
const isLocal = window.location.origin.includes("localhost");
const fetchCurrentUserInfo = isLocal ? fetchMockUserInfo : fetchRealUserInfo;

export const RoleProvider = ({ children }) => {
    const [roles, setRoles] = useState([]);
    const [email, setEmail] = useState("");
    const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);

    useEffect(() => {
        const loadUserInfo = async () => {
            setIsLoadingUserInfo(true);
            const userInfo = await fetchCurrentUserInfo();
            if (userInfo) {
                setRoles(userInfo.groups);
                setEmail(userInfo.email);
            }
            setIsLoadingUserInfo(false);
        };

        loadUserInfo();
    }, []);

    if (isLoadingUserInfo || roles?.length === 0) {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            Loading app... <CircularProgress />
        </div>
    }

    return (
        <RoleContext.Provider value={{ roles, email, isLoadingUserInfo }}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => {
    const context = useContext(RoleContext);
    if (!context) {
        throw new Error("useRole must be used within a RoleProvider");
    }
    return context;
};