import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import RestClient from "services/RestClient";
import UserManagementTable from "./Components/UserManagementTable"
import UsersUploadModal from "./Components/UsersUploadModal";
import UsersFormModal from "./Components/UsersFormModal";
import { showToastMessage } from "helpers/toastHelper";
import ConfirmationModal from 'components/ConfirmationModal';

function UserManagementPage() {
    const [openUploadUsersModal, setOpenUploadUsersModal] = useState(false);
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isEditingUser, setIsEditingUser] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isDeletingUser, setDeletingUser] = useState(false);
    const [userDeleteID, setUserDeleteID] = useState(false);
    const [userInitialData, setUserInitialData] = useState({
        name: '',
        email: '',
        group: null,
    });

    const transformTableName = (name) => {
        if (name) {
            switch (name) {
                case "KijijiSales":
                    return "Kijiji Sales";
                case "KijijiAdOps":
                    return "Kijiji AdOps";
                default:
                    return name;
            }
        } else {
            return "";
        }
    }

    const getUsersList = async () => {
        setLoadingTable(true);
        const result = await RestClient.get(`/api/users/all`);
        if (result?.status === 200) {
            if (result.data.length > 0) {
                const transformedData = result.data.map(user => ({
                    id: user.Username,
                    name: user.Attributes.find(attr => attr.Name === "name")?.Value || '',
                    email: user.Attributes.find(attr => attr.Name === "email")?.Value || '',
                    status: user.Enabled ? "Active" : "Inactive",
                    group: user.Groups[0]?.GroupName || '',
                    role: transformTableName(user.Groups[0]?.GroupName),
                }));
                setTableData(transformedData);
            }
        } else {
            showToastMessage(result?.message, "error");
        }
        setLoadingTable(false);
    };

    const handleDeleteUser = async () => {
        setDeletingUser(true);
        const result = await RestClient.delete(`/api/users`, { userIds: [userDeleteID] });
        if (result?.status === 200) {
            showToastMessage("User has been successfully deleted", "success");
            setOpenDeleteUserModal(false);
            getUsersList()
        } else {
            showToastMessage(result?.message, "error");
        }
        setDeletingUser(false);
    };

    const handleAddUser = async (newUserData) => {
        setIsAddingUser(true);
        const result = await RestClient.post(`/api/users/create`, newUserData);
        if (result?.status === 200) {
            showToastMessage("User has been successfully created", "success");
            setOpenAddUserModal(false);
            getUsersList()
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsAddingUser(false);
    };

    const handleUpdateUser = async (updatedUserData) => {
        setIsEditingUser(true);
        const result = await RestClient.put(`/api/users/${updatedUserData.id}`,
            {
                email: updatedUserData.email,
                name: updatedUserData.name,
                group: updatedUserData.group
            });
        if (result?.status === 200) {
            showToastMessage("User has been successfully updated", "success");
            setOpenEditUserModal(false);
            getUsersList()
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsEditingUser(false);
    };

    const handleOpenUserDeleteModal = (id) => {
        setUserDeleteID(id);
        setOpenDeleteUserModal(true);
    };
    const handleOpenUserEditModal = (row) => {
        setUserInitialData(row);
        setOpenEditUserModal(true);
    };

    useEffect(() => {
        getUsersList();
    }, []);

    return (
        <Container sx={{ mt: 6, mb: 6 }}>
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
                <Breadcrumbs routes={[
                    { label: "Home", route: "/campaigns" },
                    { label: "User Management" },
                ]} />
            </MKBox>
            <MKBox sx={{
                display: "flex",
                alignItems: "end",
                paddingBottom: "10px",
                justifyContent: "flex-end"
            }}>
                <MKButton
                    color="secondary"
                    sx={{ mr: 1 }}
                    onClick={() => setOpenUploadUsersModal(true)}>
                    Upload Users
                </MKButton>
                <MKButton
                    color="primary"
                    onClick={() => setOpenAddUserModal(true)}>
                    Add User
                </MKButton>
            </MKBox>
            <UsersUploadModal
                openUploadUsersModal={openUploadUsersModal}
                handleCloseUploadUsersModal={() => setOpenUploadUsersModal(false)}
                getUsersList={getUsersList}
            />
            <UsersFormModal
                openUserModal={openAddUserModal}
                handleCloseUsersModal={() => setOpenAddUserModal(false)}
                action={"create"}
                isLoading={isAddingUser}
                onSubmit={handleAddUser}
            />
            <UsersFormModal
                openUserModal={openEditUserModal}
                handleCloseUsersModal={() => setOpenEditUserModal(false)}
                action={"edit"}
                isLoading={isEditingUser}
                onSubmit={handleUpdateUser}
                initialData={userInitialData}
                getUsersList={getUsersList}
            />
            <ConfirmationModal
                openModal={openDeleteUserModal}
                modalTitle="Delete User"
                handleCloseModal={() => setOpenDeleteUserModal(false)}
                isLoading={isDeletingUser}
                handleAction={handleDeleteUser}
                actionButtonTitle="Delete User"
                actionButtonTitleLoading="Deleting User..."
                modalContent="Are you sure you want to delete this user?"
            />
            <UserManagementTable
                tableData={tableData}
                loadingTable={loadingTable}
                handleOpenUserDeleteModal={handleOpenUserDeleteModal}
                handleOpenUserEditModal={handleOpenUserEditModal}
            // updateTable={updateTable}
            />
        </Container>
    );
}

export default UserManagementPage;