import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import { NavLink } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import MKBox from "components/MKBox";
import LogoutModal from "./LogoutModal";
import { useRole } from "context/RoleContext";

const NavBar = () => {
  const { roles } = useRole();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const openMenu = Boolean(anchorEl);
  const openAdminMenu = Boolean(adminAnchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenAdminMenu = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };
  const handleCloseAdminMenu = () => {
    setAdminAnchorEl(null);
  };

  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    handleCloseMenu();
    setOpenLogoutModal(false);
  };

  return (
    <MKBox bgColor="white" shadow="sm" py={0.25}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Kijiji Autos Banners
          </Typography>
          {(roles.includes("Admin") || roles.includes("Super-Admin")) && (
            <>
              <Button color="inherit" component={NavLink} to="/dealers">Dealers</Button>
              <Button color="inherit" component={NavLink} to="/campaigns">Campaigns</Button>
              <Button color="inherit" onClick={handleOpenAdminMenu}>Admin</Button>
              <Menu
                id="admin-menu"
                anchorEl={adminAnchorEl}
                open={openAdminMenu}
                onClose={handleCloseAdminMenu}
                MenuListProps={{
                  "aria-labelledby": "admin-button",
                }}
              >
                <MenuItem component={NavLink} to="/template-suites" onClick={handleCloseAdminMenu}>Template Suites</MenuItem>
                <MenuItem component={NavLink} to="/user-management" onClick={handleCloseAdminMenu}>User Management</MenuItem>
              </Menu>
              <Button color="inherit" component={NavLink} to="/reports">Reports</Button>
            </>
          )}

          <Tooltip title="Settings" color="secondary">
            <IconButton
              color="secondary"
              onClick={handleOpenMenu}>
              <AccountCircle fontSize="large" />
            </IconButton>
          </Tooltip>
          <Menu
            id="logout-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "logout-button",
            }}
          >
            <MenuItem onClick={handleOpenLogoutModal}>Logout</MenuItem>
          </Menu>
          <LogoutModal
            openLogoutModal={openLogoutModal}
            handleCloseLogoutModal={handleCloseLogoutModal}
          />
        </Toolbar>
      </AppBar>
    </MKBox>
  );
};

export default NavBar;