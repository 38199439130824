import React, { useState, useEffect } from 'react';

import CampaignDetailForm from "./CampaignDetailForm";
import CampaignCreate from "components/campaigns/CampaignCreateModal";
import RestClient from 'services/RestClient';

function CampaignInfo({ campaign }) {
  const [CampaignData, setCampaignData] = useState(campaign);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const campaignAction = {
    "destination": "internal",
    "target": "/campaigns/campaign/{id}",
    "keys": ["id"]
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
        const result = await RestClient.get(`/api/campaigns/campaign/${campaign.id}`);
        
        if (result?.data[0]) {
         setCampaignData(result.data[0]); 
        }
      setIsLoading(false);
    };
    if (campaign && !CampaignData) {
      fetchData();
    }
  }, [campaign]);


  return (
    <>
      {isLoading ? (
        <p>Loading Campaign data...</p>
      ) : (
        <>
          <CampaignDetailForm campaign={CampaignData} />
        </>
      )}
    </>
  );
}

export default CampaignInfo;
