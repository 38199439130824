export const fetchCurrentUserInfo = async () => {
    console.log("mock auth service is called");
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                groups: ["Super-Admin"], // roles: KijijiAdOps, KijijiSales, Admin, Super-Admin
                email: "email@test.com"
            });
        }, 500);
    });
};