import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress} from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import MKButton from 'components/MKButton';
import RestClient from 'services/RestClient';
import { showToastMessage } from 'helpers/toastHelper';

const LogoutModal = ({ openLogoutModal, handleCloseLogoutModal }) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogOut = async () => {
    setIsLoggingOut(true);
    const result = await RestClient.get(`/logout`);
    if (!result?.Location) {
      showToastMessage(result?.message, "error");
    }
    setIsLoggingOut(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth open={openLogoutModal} onClose={handleCloseLogoutModal}>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogContent>
        Are you sure?
      </DialogContent>
      <DialogActions>
        <MKButton onClick={handleCloseLogoutModal}>Cancel</MKButton>
        <MKButton endIcon={isLoggingOut ? <CircularProgress color="inherit" size={20} /> : <LogoutOutlined />} color="primary" disabled={isLoggingOut} onClick={handleLogOut}>
          {isLoggingOut ? "Logging Out..." : "Logout"}
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;