import React, { useState, useEffect } from 'react';
import { Card, Grid, CircularProgress, Container, Paper } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CampaignsDashboardMetrics({ dealerCount, campaignCount, loadingMetrics }) {
    return (
        <MKBox sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
                m: 2,
                width: 160,
                height: 150,
            },
        }}>
            <Card style={{ padding: 25, margin: 10, marginRight: 15, marginLeft: 0 }}>
                <Grid container direction="column" alignContent="center" justifyContent="center" spacing={1}>
                    <Grid item>
                        {loadingMetrics ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <MKTypography variant="h2" align="center">{campaignCount}</MKTypography>
                        )}
                    </Grid>
                    <Grid item>
                        <MKTypography variant="body2" align="center">Campaigns This Month</MKTypography>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ padding: 25, margin: 10, marginLeft: 0 }}>
                <Grid container direction="column" alignContent="center" justifyContent="center" spacing={1}>
                    <Grid item>
                        {loadingMetrics ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <MKTypography variant="h2" align="center">{dealerCount}</MKTypography>
                        )}
                    </Grid>
                    <Grid item>
                        <MKTypography variant="body2" align="center">Active Dealers</MKTypography>
                    </Grid>
                </Grid>
            </Card>
        </MKBox>
    );
}

export default CampaignsDashboardMetrics;