import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import TemplateSuitesForm from "../Components/TemplateSuitesForm";
import RestClient from "services/RestClient";
import { showToastMessage } from "../../../helpers/toastHelper";

function TemplateSuitesCreatePage() {
    const navigate = useNavigate();
    const [isCreating, setIsCreating] = useState(false);

    const handleTemplateSuiteCreate = async (formData) => {
        setIsCreating(true);
        const result = await RestClient.post(`/api/template_suites`, formData);
        if (result?.status === 200) {
            navigate("/template-suites");
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsCreating(false);
    };

    return (
        <Container sx={{ mt: 6, mb: 6 }}>
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
                <Breadcrumbs routes={[
                    { label: "Home", route: "/campaigns" },
                    { label: "Template Suites", route: "/template-suites" },
                    { label: "Add Template Suite" },
                ]} />
            </MKBox>
            <MKTypography variant="h3" sx={{ mb: 3, mt: 2 }}>
                Add Template Suite
            </MKTypography>
            <TemplateSuitesForm
                action={"create"}
                onSubmit={handleTemplateSuiteCreate}
                loadingButtonTitle={"Adding Template..."}
                buttonTitle={"Add Template"}
                isLoading={isCreating}
            />
        </Container>
    );
}

export default TemplateSuitesCreatePage;