import React, { useEffect } from "react";

import { BrowserRouter as Router, Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import CampaignsDashboardPage from "pages/CampaignsDashboard/CampaignsDashboardPage";
import DealersDashboardPage from "pages/DealersDashboard/DealersDashboardPage";
import DealerDetailPage from "components/dealers/view";
import CampaignDetailPage from "components/campaigns/CampaignDetailPage";
import TemplateSuitesPage from "pages/Templates/Pages/TemplateSuitesPage";
import TemplateSuitesEditPage from "pages/Templates/Pages/TemplateSuitesEditPage";
import TemplateSuitesCreatePage from "pages/Templates/Pages/TemplateSuitesCreatePage";
import ReportsPage from "pages/Reports/ReportsPage";
import UserManagementPage from "pages/UserManagement/UserManagementPage";
import MainWrapper from "layouts/MainWrapper";
import NavBar from "layouts/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RoleProvider } from "context/RoleContext";
import ProtectedRoute from "routes/ProtectedRoute";

export default function App() {

  const MainLayout = () => {
    return (
      <MainWrapper>
        <NavBar />
        <ToastContainer />
        <Outlet />
      </MainWrapper>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RoleProvider>
        <Router>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<CampaignsDashboardPage />} />
              <Route path="/campaigns" element={<CampaignsDashboardPage />} />
              <Route path="/dealers" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<DealersDashboardPage />} />} />
              <Route path="/dealers/dealer/:id" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<DealerDetailPage />} />} />
              <Route path="/campaigns/campaign/:id" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<CampaignDetailPage />} />} />
              <Route path="/template-suites" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<TemplateSuitesPage />} />} />
              <Route path="/template-suites/edit/:id" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<TemplateSuitesEditPage />} />} />
              <Route path="/template-suites/create" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<TemplateSuitesCreatePage />} />} />
              <Route path="/reports" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<ReportsPage />} />} />
              <Route path="/user-management" element={<ProtectedRoute allowedRoles={["Admin", "Super-Admin"]} element={<UserManagementPage />} />} />
            </Route>
            <Route path="*" element={<Navigate to="/campaigns" />} />
          </Routes>
        </Router>
      </RoleProvider>

    </ThemeProvider>
  );
}
