import React, { useState, useEffect } from 'react';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import Targetting from './targetting';
import { styled } from '@mui/material/styles';
import { Tab, Tabs, Grid, CircularProgress, Box, Autocomplete, Tooltip, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { InfoOutlined, ArrowForwardIosSharp } from '@mui/icons-material';
import { showToastMessage } from '../../helpers/toastHelper';
import RestClient from 'services/RestClient';
import { validateQueryString, validateVariableInput } from 'helpers/helpers';
import TemplateVariablesForm from 'pages/Templates/Components/TemplateVariablesForm';

const emptyCampaign = {
    id: null,
    requested: "",
    dealer: null,
    dealer_id: null,
    gam_campaign_id: null,
    startdate: null,
    enddate: null,
    notes: "",
    template_suite_id: "",
    custom_variables: [],
    line_items: []
};

function CampaignCreateModalForm({ dealer, onCreate }) {
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState(emptyCampaign);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [dealersOptions, setDealersOptions] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedDealer, setSelectedDealer] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loadingDealerOptions, setLoadingDealerOptions] = useState(false);
    const [loadingTemplateOptions, setLoadingTemplateOptions] = useState(false);
    const [queryStringError, setQueryStringError] = useState(false);
    const [isLoadingVariables, setIsLoadingVariables] = useState(false);
    const [rows, setRows] = useState([]);

    const currentDate = new Date().toISOString().replace(/T.*/, '')

    const createCampaign = async (event) => {
        event.preventDefault();
        let payload = {};
        if (queryStringError) {
            payload = { ...formData, query_string: "" }
        } else {
            payload = { ...formData };
        }
        setIsCreating(true);
        const result = await RestClient.post("/api/campaign/new", payload);
        if (result.status === 200) {
            setFormData(emptyCampaign);
            onCreate();
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsCreating(false);
    };

    const getTemplatesList = async () => {
        setLoadingTemplateOptions(true);
        const result = await RestClient.get(`/api/template_suites`);
        if (result?.data) {
            const options = result?.data?.map(template => ({
                id: template.id,
                label: template.name
            }))
            setTemplateOptions(options);
        } else if (result?.status !== 200) {
            showToastMessage(result?.message, "error");
        }
        setLoadingTemplateOptions(false);
    }

    const fetchTemplateSuite = async (id) => {
        setIsLoadingVariables(true);
        const result = await RestClient.get(`/api/template_suites/${id}`);
        if (result?.status === 200) {
            if (result.data) {
                const initialValues = result.data[0];
                let variablesArray = [];
                if (typeof initialValues.custom_variables === 'object') {
                    variablesArray = Object.values(initialValues.custom_variables);
                    if (variablesArray.length > 0) {
                        setFormData({ ...formData, custom_variables: variablesArray, template_suite_id: id });
                        setRows(variablesArray);
                    } else {
                        setFormData({ ...formData, custom_variables: [], template_suite_id: id });
                        setRows([]);
                    }
                }
            }
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsLoadingVariables(false);
    };

    const handleTemplateChange = (e, newValue) => {
        if (newValue !== null) {
            fetchTemplateSuite(newValue.id);
            setSelectedTemplate(newValue);
        } else if (newValue === null) {
            setSelectedTemplate(newValue);
            setFormData({ ...formData, template_suite_id: "" })
        }
    }

    const updateCustomVariables = (variables) => {
        const formattedData = variables.map(row => ({
            id: row.id,
            variable_value: validateVariableInput(row.variable_value, row.custom_variables_type_id) ? row.variable_value : '',
            extension: (row.custom_variables_type_id === 3 && row.file_name) ? row.file_name.split('.').pop() : false
        }));
        setFormData({ ...formData, custom_variables: formattedData });
    };

    const handleGAMChange = (value) => {
        const newValue = [value];
        setFormData({
            ...formData,
            line_items: newValue
        })
    };

    const CustomAccordionSummary = styled((props) => (
        <AccordionSummary
            expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const getDealersList = async () => {
        setLoadingDealerOptions(true);
        const res = await RestClient.get('/api/dealers');
        if (res?.dealers) {
            const options = res?.dealers?.map(dealer => ({
                id: dealer.id,
                label: dealer.name
            }))
            setDealersOptions(options);
            setLoadingDealerOptions(false);
        } else if (res?.status !== 200) {
            showToastMessage(res?.message, "error");
        }
        setLoadingDealerOptions(false);
    }

    const handleDealerChange = (e, newValue) => {
        if (newValue !== null) {
            setSelectedDealer(newValue);
            setFormData({ ...formData, dealer: newValue.label, dealer_id: newValue.id })
        } else if (newValue === null) {
            setSelectedDealer(newValue);
            setFormData({ ...formData, dealer: "", dealer_id: "" })
        }
    }

    const handleStartDateChange = (e) => {
        setStartDateError("");
        if (e.target.value) {
            if ((formData.enddate <= e.target.value) && (formData.enddate !== "")) {
                setFormData({ ...formData, startdate: "" });
                setStartDateError("Incorrect entry");
            } else {
                setFormData({ ...formData, startdate: e.target.value });
            }
        }
    };

    const handleEndDateChange = (e) => {
        setEndDateError("");
        if (e.target.value) {
            const isDateInvalid = (e.target.value <= formData.startdate) || (e.target.value <= currentDate)
            if (isDateInvalid && (formData.startdate !== "")) {
                setFormData({ ...formData, enddate: "" });
                setEndDateError("Incorrect entry");
            } else {
                setFormData({ ...formData, enddate: e.target.value });
            }
        }
    };

    const handleQueryString = (e) => {
        setQueryStringError(false);
        const value = e.target.value;
        setFormData({ ...formData, query_string: e.target.value });
        if (!validateQueryString(value) && value !== "") {
            setQueryStringError(!validateQueryString(value));
        }
    };

    useEffect(() => {
        if (dealer) {
            setFormData({ ...formData, dealer: dealer.name, dealer_id: dealer.id });
        } else {
            setFormData({ ...formData, dealer: null, dealer_id: null })
        }
    }, [dealer]);

    useEffect(() => {
        if (!dealer) {
            getDealersList();
        }
        getTemplatesList()
    }, []);

    return (
        <MKBox width="100%" >
            <MKBox p={3}>
                <Grid container spacing={3} component="form" autoComplete="off" onSubmit={createCampaign}>
                    <Grid item xs={12} md={6}>
                        {dealer ? (
                            <MKInput variant="standard" disabled={true} label="Dealer" fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={formData.dealer} />
                        ) : (
                            <Autocomplete
                                options={dealersOptions}
                                getOptionLabel={option => option.label}
                                value={selectedDealer}
                                onChange={handleDealerChange}
                                renderInput={params => (
                                    <MKInput
                                        {...params}
                                        label="Dealer"
                                        variant="standard"
                                        placeholder="Select Dealer"
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingDealerOptions ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput variant="standard" disabled={true} label="Date Requested" fullWidth value={formData.requested} onChange={(e) => setFormData({ ...formData, requested: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput
                            variant="standard"
                            required
                            type="date"
                            label="Start Date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={startDateError}
                            helperText={startDateError}
                            value={formData.startdate}
                            onChange={handleStartDateChange} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput
                            variant="standard"
                            required
                            type="date"
                            label="End Date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={endDateError}
                            helperText={endDateError}
                            value={formData.enddate}
                            onChange={handleEndDateChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <MKInput variant="standard"
                            type="string" label="GAM Campaign"
                            fullWidth value={formData.gam_campaign_id}
                            onChange={(e) => setFormData({ ...formData, gam_campaign_id: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput variant="standard" label="GAM LineItem ID" fullWidth
                            value={formData.line_items[0] ?
                                formData.line_items[0] : ""}
                            onChange={(e) => handleGAMChange(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput variant="standard" label="GAM Creative ID" fullWidth disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            options={templateOptions}
                            getOptionLabel={option => option.label}
                            value={selectedTemplate}
                            onChange={handleTemplateChange}
                            renderInput={params => (
                                <MKInput
                                    {...params}
                                    label="Template Suite"
                                    placeholder="Select template suite"
                                    variant="standard"
                                    required
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingTemplateOptions ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Accordion sx={{
                            boxShadow: "none",
                            "&:before": {
                                display: "none",
                            },
                        }}
                        >
                            <CustomAccordionSummary
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <MKTypography variant="h6" color="secondary">Campaign Variables</MKTypography>
                                {isLoadingVariables && <CircularProgress color="inherit" size={19} sx={{ ml: 1 }} />}
                            </CustomAccordionSummary>
                            <AccordionDetails sx={{ fontSize: '15px', p: 0 }}>
                                <Grid container spacing={2}>
                                    {rows.length > 0 ? (
                                        <TemplateVariablesForm
                                            rows={rows}
                                            setRows={setRows}
                                            updateFormData={updateCustomVariables}
                                            editable={false} />
                                    ) : (
                                        <MKTypography variant="body2" color="secondary">No Custom Variables available</MKTypography>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput variant="standard" label="Sales Rep" fullWidth rows={6} value={formData.sales_rep}
                            onChange={(e) => setFormData({ ...formData, sales_rep: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKInput variant="standard" label="Notes" fullWidth rows={6} value={formData.notes}
                            onChange={(e) => setFormData({ ...formData, notes: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MKInput variant="standard"
                            label={<div style={{ display: "flex", alignItems: "center" }}>
                                Query String Suffix
                                <Tooltip
                                    title="Use this field to add tracking parameters to your URLs. Query string
suffixes help you monitor the effectiveness of your campaigns by
capturing details like source, medium, and campaign name.">
                                    <InfoOutlined fontSize="small" sx={{ ml: 1 }}></InfoOutlined>
                                </Tooltip>
                            </div>}
                            fullWidth value={formData.query_string}
                            onChange={handleQueryString}
                            error={queryStringError}
                            helperText={queryStringError ? "Invalid query string format" : ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MKButton type="submit" variant="gradient" disabled={isCreating} color="primary" endIcon={isCreating ? <CircularProgress color="inherit" size={20} /> : ""}>
                            {isCreating ? "Adding Campaign..." : "Add Campaign"}
                        </MKButton>
                    </Grid>
                </Grid>
            </MKBox>
        </MKBox>
    );
}

export default CampaignCreateModalForm;