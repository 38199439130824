import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';

const ConfirmationModal = ({
  openModal,
  handleCloseModal,
  modalTitle,
  modalContent,
  isLoading = false,
  handleAction,
  actionButtonTitle,
  actionButtonTitleLoading = actionButtonTitle,
  maxWidth = "xs",
  disableAction = false
}) => {

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth open={openModal} onClose={handleCloseModal}>
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        {modalContent}
      </DialogContent>
      <DialogActions>
        <MKButton onClick={handleCloseModal}>Cancel</MKButton>
        <MKButton endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : null} color="primary" disabled={isLoading || disableAction} onClick={handleAction}>
          {isLoading ? actionButtonTitleLoading : actionButtonTitle}
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;