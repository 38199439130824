import React from 'react';
import MKBox from "components/MKBox";

const MainWrapper = ({ children }) => {
  return (
    <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
        {children}
    </MKBox>
  );
};

export default MainWrapper;