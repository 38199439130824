import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import TemplateSuitesTable from "../Components/TemplateSuitesTable";
import RestClient from "services/RestClient";
import { showToastMessage } from "../../../helpers/toastHelper";

function TemplateSuitesPage() {
  const navigate = useNavigate();
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableData, setTableData] = useState([]);

  const fetchTableData = async () => {
    setLoadingTable(true);
    const result = await RestClient.get(`/api/template_suites`);
    if (result?.data) {
      setTableData(result.data);
    } else if (result?.status !== 200) {
      showToastMessage(result?.message, "error");
    }
    setLoadingTable(false);
  };

  const updateTable = () => {
    fetchTableData();
  }

  useEffect(() => {
    updateTable();
  }, []);

  return (
    <Container sx={{ mt: 6, mb: 6 }}>
      <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
        <Breadcrumbs routes={[
          { label: "Home", route: "/campaigns" },
          { label: "Template Suites" },
        ]} />
      </MKBox>
      <MKBox sx={{
        display: "flex",
        alignItems: "end",
        paddingBottom: "10px",
        justifyContent: "flex-end"
      }}>
        <MKButton
          color="primary"
          onClick={() => navigate("/template-suites/create")}>
          Add Template Suite
        </MKButton>
      </MKBox>
      <TemplateSuitesTable
        tableData={tableData}
        loadingTable={loadingTable}
        updateTable={updateTable}
      />
    </Container>
  );
}

export default TemplateSuitesPage;