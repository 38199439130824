import React, { useState, useEffect } from 'react';
import MKTypography from 'components/MKTypography';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import { Checkbox, Autocomplete, Grid, Card, CardContent, CardMedia, CircularProgress, Box } from '@mui/material'
import RestClient from 'services/RestClient';

function RobotResultsView({
	onRefreshing,
	vehiclesData,
	refreshresults,
	campaignID,
	isUpdating,
	onUpdateCampaign,
	onSelectedItemsChange,
	disableUpdateButton,
	lastRefreshedDate }) {
	const [selectedMake, setSelectedMake] = useState([]);
	const [selectedModel, setSelectedModel] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [vehicleData, setVehicleData] = useState(vehiclesData ? vehiclesData : []);
	const [makeOptions, setMakeOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
	const [lastRefreshDate, setLastRefreshDate] = useState("Last Refreshed: N/A");

	const handleMakeFilterChange = (event, newMake) => {
		setSelectedMake(newMake);
	};
	const handleModelFilterChange = (event, newModel) => {
		setSelectedModel(newModel);
	};

	const handleSelectAll = () => {
		setIsUpdateButtonDisabled(false);
		setSelectedItems(filteredVehicles.map(vehicle => vehicle.Id))
	}
	const handleClearAll = () => {
		setSelectedItems([]);
	}

	const fetchVehiclesData = async (id) => {
		setIsLoading(true);
		const json = await RestClient.get(`/api/campaign/${id}/vehicles`);
		if (json?.data) {
			setVehicleData(json.data);
			if (json.data[0]?.Updated) {
				setLastRefreshDate(json.data[0]?.Updated);
			}
		}
		setIsLoading(false);
	};

	// const refresh = async (e) => {
	// 	const img_params = e.target.src.match(/\d+/g)
	// 	await fetch(`/api/robots/refreshImage/robot/${img_params[0]}/image/${img_params[1]}`);
	// 	setVehicleData([]);
	// 	return refreshresults();
	// }

	const handleCheckboxChange = (itemId) => {
		setIsUpdateButtonDisabled(false);
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter(id => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const filteredVehicles = vehicleData.filter(item => {
		if (selectedMake.length > 0 && !selectedMake.includes(item.Make)) {
			return false;
		}
		if (selectedModel.length > 0 && !selectedModel.includes(item.Model)) {
			return false;
		}
		return true;
	});

	useEffect(() => {
		const initiallySelectedIds = vehicleData.map(vehicle => vehicle.Selected ? vehicle.Id : null).filter(id => id !== null);
		if (initiallySelectedIds.length >= 10) {
			setSelectedItems(initiallySelectedIds);
		} else {
			setSelectedItems(vehicleData.map(vehicle => vehicle.Id));
		}
	}, [vehicleData]);

	useEffect(() => {
		fetchVehiclesData(campaignID);
	}, [campaignID]);

	useEffect(() => {
		setIsUpdateButtonDisabled(disableUpdateButton);
	}, [disableUpdateButton]);

	useEffect(() => {
		if (lastRefreshedDate) {
			setLastRefreshDate(lastRefreshedDate);
		}
	}, [lastRefreshedDate]);

	useEffect(() => {
		if (vehicleData?.length > 0) {
			const uniqueMakes = Array.from(new Set(vehicleData.map(vehicle => vehicle.Make)));
			const uniqueModels = Array.from(new Set(vehicleData.map(vehicle => vehicle.Model)));
			setMakeOptions(uniqueMakes);
			setModelOptions(uniqueModels);
		}
	}, [vehicleData]);

	useEffect(() => {
		onSelectedItemsChange(selectedItems);
	}, [selectedItems]);

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} md={3}>
					<Autocomplete
						multiple
						limitTags={2}
						id="tags-standard"
						options={makeOptions}
						value={selectedMake}
						onChange={handleMakeFilterChange}
						getOptionLabel={(option) => option}
						renderInput={(params) => (
							<MKInput
								{...params}
								variant="standard"
								label="Make"
								placeholder="Manufacturer"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						multiple
						limitTags={2}
						id="tags-standard"
						options={modelOptions}
						value={selectedModel}
						onChange={handleModelFilterChange}
						getOptionLabel={(option) => option}
						renderInput={(params) => (
							<MKInput
								{...params}
								variant="standard"
								label="Model"
								placeholder="Model"
							/>
						)}
					/>
				</Grid>
				{/* <Grid item xs={1}>
					<MKButton onClick={handleSelectAll} variant="text" color="secondary" fullWidth >Select All</MKButton>
				</Grid>
				<Grid item xs={1}>
					<MKButton onClick={handleClearAll} variant="text" color="secondary" fullWidth >Clear All</MKButton>
				</Grid> */}
				<Grid item xs={12} md={6}>
					<Grid container spacing={1} justifyContent="flex-end">
						<Grid item xs={12} md={4}>
							<MKButton disabled={isUpdateButtonDisabled} onClick={onUpdateCampaign} variant="gradient" color="primary" fullWidth>
								{isUpdating ? "Updating..." : "Update"}
							</MKButton>
						</Grid>
						<Grid item xs={12} md={4}>
							<Grid container justifyContent="flex-end">
								<MKButton onClick={refreshresults} variant="outlined" color="secondary" fullWidth> {onRefreshing ? "Refreshing..." : "Refresh"}</MKButton>
								<MKTypography variant="body2" color="secondary">
									{lastRefreshDate}
								</MKTypography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				{isLoading || onRefreshing ? (
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						{error && (
							<Grid item xs={12}>
								<MKTypography variant="body1" color="error">
									Error: {error.message}
								</MKTypography>
							</Grid>
						)}

						{!isLoading && !error && filteredVehicles.length > 0 && (
							filteredVehicles.map((item, index) => (
								<Grid item key={index} xs={12} md={6} lg={3}>
									<Card sx={{ maxWidth: 300 }}>
										<CardMedia
											component="img"
											height="140"
											image={`${item.Image}`}
										/>
										<CardContent>
											<MKTypography sx={{ fontSize: 14 }} variant="body2">Year: {item.Year}</MKTypography>
											<MKTypography sx={{ fontSize: 14 }} variant="body2">Make: {item.Make}</MKTypography>
											<MKTypography sx={{ fontSize: 14 }} variant="body2">Model: {item.Model}</MKTypography>
											<MKTypography sx={{ fontSize: 14 }} variant="body2">Link: <a href={item.Link} target="_blank" rel="noopener noreferrer">{item.Link}</a></MKTypography>
											{/* <Checkbox checked={selectedItems.includes(item.Id)} onChange={() => handleCheckboxChange(item.Id)} style={{ position: 'absolute', bottom: 0, right: 0, }} /> */}
										</CardContent>
									</Card>
								</Grid>
							))
						)}
					</>
				)}
				{!isLoading && !error && filteredVehicles.length === 0 && (
					<Grid item xs={12}>
						<MKTypography variant="body1">No vehicles found.</MKTypography>
					</Grid>
				)}
			</Grid>
		</>
	);
}

export default RobotResultsView