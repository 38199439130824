import React, { useState } from 'react';
import { Button, Popover, IconButton } from '@mui/material';
import { SketchPicker } from 'react-color';
import ColorizeIcon from '@mui/icons-material/Colorize';
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

const ColorPickerPopover = ({ onChangeComplete, initialValue }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [color, setColor] = useState(initialValue?.variable_value ? initialValue?.variable_value : '#fff');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeComplete = (color) => {
        setColor(color.hex);
        onChangeComplete(initialValue.id, 'variable_value', color.hex);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'color-picker-popover' : undefined;

    return (
        <>
            <MKBox sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                flexDirection: "row"
            }}>
                <MKButton
                    color='info'
                    sx={{ mr: 1.5 }}
                    onClick={handleClick}>
                    <ColorizeIcon />
                </MKButton>
                <MKTypography variant="body2">{color ? color : "Select color"}</MKTypography>
            </MKBox>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
            </Popover>
        </>
    );
};

export default ColorPickerPopover;