import React, { useState, useEffect } from 'react';
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import { Link } from '@mui/material';
const SearchableTable = ({ data, action }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filters, setFilters] = useState({});
  const [hasData, setHasData] = useState(false);
  // const baseAction = useState(action);
  const navigate = useNavigate();
  useEffect(() => {
    if (data && data.length != 0) {
      setHasData(true);
      // Apply initial filtering
      setFilteredData(data.filter(row => {
        return Object.keys(filters).every(key => {
          const value = filters[key].toLowerCase();
          return !value || String(row[key]).toLowerCase().includes(value);
        });
      }));
    }
  }, [data, filters]);

  const handleFilterChange = (event, column) => {
    setFilters({
      ...filters,
      [column]: event.target.value.toLowerCase(),
    });
  };

  const renderHeaders = () => {
    return Object.keys(data[0]).map(column => (
      <th key={column}>
        <MKTypography variant="body2" component="p" color="dark">
          {column}
        </MKTypography>
        <input
          type="text"
          placeholder={"Search by " + column}
          value={filters[column]}
          onChange={event => handleFilterChange(event, column)}
        />
      </th>
    ));
  };
  const handleRowClick = (rowData) => {
    let target = action.target;
    for (const key of action.keys) {
      const r = new RegExp(`{${key}}`, "g");
      target = target.replace(r, rowData[key])
    }

    navigate(target);
  }
  const renderRows = () => {
    return filteredData.map(row => (
      <tr key={row.id} onClick={() => handleRowClick(row)}>
        {Object.entries(row).map(([key, value], index) => (
          <td key={index}>
            {key === "id" ? (
              <Link component="button" variant="body2" underline="always"
              >
                {value}
              </Link>
            ) : (
              <MKTypography variant="body2" component="p" color="dark">
                {value}</MKTypography>
            )}
          </td>

        ))}
      </tr>
    ));
  };

  return (
    <>
      {hasData ? (
        <table style={{ width: "100%", border: '1px solid dark', borderRadius: '10px' }}>
          <thead>
            <tr>{renderHeaders()}</tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      ) : ("No Data")}
    </>
  );
};

export default SearchableTable;