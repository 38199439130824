import { toast } from 'react-toastify'; 

const toastOptions = { 
  position: 'top-center', 
  autoClose: 5000, 
  theme: 'light', 
}; 

export const showToastMessage = ( 
  message, 
  type // 'success' | 'error' | 'warning' | 'info' 
) => { 
  const toastId = `new-toast-${type}`; 
  const existingToast = toast.isActive(toastId); 
  if (!existingToast) { 
    toast[type](message, { ...toastOptions, toastId }); 
  } 
};