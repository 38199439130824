import React, { useState, useEffect } from 'react';
import { Chip, Box, Grid } from '@mui/material';
import MKButton from 'components/MKButton';
import MKInput from 'components/MKInput';
import MKBox from 'components/MKBox';

function EmailBannerForm({ handleEmailsChange, emailRecipientsMessage, emailsList }) {
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const handleInputChange = (event) => {
    setEmailInput(event.target.value);
    setEmailErrorMessage("");
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== '') {
      const isValidEmail = validateEmail(emailInput.trim());
      if (!isValidEmail) {
        setEmailErrorMessage("Invalid email address");
        return;
      }
      const newEmails = [...emails, emailInput.trim()]
      setEmails(newEmails);
      handleEmailsChange(newEmails);
      setEmailInput("");
      setEmailErrorMessage("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
    handleEmailsChange(updatedEmails);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (emailsList?.length > 0) {
      setEmails(emailsList);
    }
  }, [emailsList]);

  useEffect(() => {
    if (emailRecipientsMessage) {
      setEmailErrorMessage(emailRecipientsMessage);
    }
  }, [emailRecipientsMessage]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginBottom: '10px' }}
      >
        {emails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={() => handleRemoveEmail(index)}
            color="secondary"
            variant="outlined"
          />
        ))}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={10} md={9}>
          <MKInput
            type="email"
            value={emailInput}
            onChange={handleInputChange}
            label="Enter email"
            variant="outlined"
            error={emailErrorMessage}
            helperText={emailErrorMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={10} md={1} style={{ marginTop: '2px' }}>
          <MKButton fullWidth variant="outlined" color="secondary" disabled={!emailInput} onClick={handleAddEmail}>
            Add
          </MKButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default EmailBannerForm;