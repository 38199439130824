import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField } from '@mui/material';
import MKInput from 'components/MKInput';
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { findMatchingBannerSizes } from "helpers/helpers";
import TemplateVariablesForm from "./TemplateVariablesForm";
import { convertFileToBase64, validateVariableInput } from "../../../helpers/helpers";

const emptyTemplateSuite = {
    id: null,
    name: "",
    description: "",
    banners: [],
    custom_variables: []
};

const templateSizes = [
    {
        id: 1,
        name: "Medium Rectangle 300x250",
        width: 300,
        height: 250,
    },
    {
        id: 2,
        name: "Half Page 300x600",
        width: 300,
        height: 600,
    },
    {
        id: 3,
        name: "Leaderboard 728x90",
        width: 728,
        height: 90,
    },
    {
        id: 4,
        name: "Billboard 970x250",
        width: 970,
        height: 250,
    },
]

const TemplateSuitesForm = ({ initialValues, action, onSubmit, buttonTitle, loadingButtonTitle, isLoading }) => {
    const [formData, setFormData] = useState(emptyTemplateSuite);
    const [rows, setRows] = useState([]);

    const convertHTMLtoBase64 = (html) => {
        const uint8Array = new TextEncoder().encode(html);
        let binaryString = '';
        uint8Array.forEach(byte => {
            binaryString += String.fromCharCode(byte);
        });
        return btoa(binaryString);
    };

    const handleUploadHTML = async (e, size) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const base64 = await convertFileToBase64(file);
                const newBanner = {
                    name: file.name.substring(0, file.name.length - 5),
                    width: size.width,
                    height: size.height,
                    template: base64
                };

                const existingBannerIndex = formData.banners.findIndex(
                    (banner) => banner.width === size.width && banner.height === size.height
                );

                let updatedBanners;

                if (existingBannerIndex !== -1) {
                    updatedBanners = formData.banners.map((banner, index) =>
                        index === existingBannerIndex ? newBanner : banner
                    );
                } else {
                    updatedBanners = [...formData.banners, newBanner];
                }

                setFormData({ ...formData, banners: updatedBanners });
            } catch (error) {
                console.error('Error converting file to base64:', error);
            }
        }
    };

    const updateCustomVariables = (variables) => {
        const formattedData = variables.map(row => ({
            custom_variables_type_id: row.custom_variables_type_id,
            variable_value: validateVariableInput(row.variable_value, row.custom_variables_type_id) ? row.variable_value : '',
            variable_name: row.variable_name,
            extension: (row.custom_variables_type_id === 3 && row.file_name) ? row.file_name.split('.').pop() : false
        }));
        setFormData({ ...formData, custom_variables: formattedData });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const filteredArray = formData?.banners?.filter(obj => obj.template);
        onSubmit({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            banners: filteredArray,
            custom_variables: Object.entries(formData.custom_variables)?.length === 0 ? [] : formData.custom_variables
        });

    };

    useEffect(() => {
        if (initialValues) {
            let variablesArray = [];
            if (Array.isArray(initialValues.custom_variables)) {
                variablesArray = initialValues.custom_variables;
            } else if (typeof initialValues.custom_variables === 'object') {
                variablesArray = Object.values(initialValues.custom_variables);
            }
            if (variablesArray.length > 0) {
                setRows(variablesArray);
                setFormData({ ...initialValues, custom_variables: variablesArray });
            } else {
                setRows([]);
                setFormData({ ...initialValues, custom_variables: [] });
            }

            const convertBanners = () => {
                let bannersArray = [];
                if (Array.isArray(initialValues.banners)) {
                    bannersArray = initialValues.banners;
                } else if (typeof initialValues.banners === 'object') {
                    bannersArray = Object.values(initialValues.banners);
                }

                if (bannersArray.length > 0) {
                    const convertedBanners = bannersArray.map((banner) => ({
                        ...banner,
                        template: convertHTMLtoBase64(banner.template)
                    }));
                    setFormData({ ...initialValues, 
                        banners: convertedBanners, 
                        custom_variables: variablesArray.length > 0 ? variablesArray : [] });
                }
            }
            convertBanners();
        }
    }, [initialValues]);

    return (
        <MKBox width="70%" >
            <Grid container spacing={3} component="form" autoComplete="off" onSubmit={handleSubmit}>
                <Grid item xs={12} md={12}>
                    <MKInput required variant="standard" label="Template Suite Name" fullWidth rows={6} value={formData?.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        inputProps={{
                            maxLength: 255
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <MKInput required variant="standard" label="Template Suite Description" fullWidth rows={6} value={formData?.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        inputProps={{
                            maxLength: 255
                        }}
                    />
                </Grid>
                <TemplateVariablesForm
                    rows={rows}
                    setRows={setRows}
                    updateFormData={updateCustomVariables}
                    editable={true} />
                <TableContainer sx={{ margin: 3, marginTop: 4, borderRadius: 1 }}>
                    <Table>
                        <TableHead sx={{
                            display: "table-header-group", "& th": {
                                color: "#1f2020",
                                backgroundColor: "#f0f2f5"
                            }
                        }}>
                            <TableRow>
                                <TableCell>Banner Size</TableCell>
                                <TableCell>Upload HTML</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templateSizes.map((size) => (
                                <TableRow key={size.id}>
                                    <TableCell>{size.name}</TableCell>
                                    <TableCell>
                                        <Grid flexDirection="row" spacing={2} container>
                                            <Grid item alignContent="center">
                                                <input
                                                    id={`upload-html-${size.id}`}
                                                    type="file"
                                                    onChange={(e) => handleUploadHTML(e, size)}
                                                    style={{ display: 'none' }}
                                                />
                                                <MKButton
                                                    variant="outlined" color="secondary" onClick={() => document.getElementById(`upload-html-${size.id}`).click()}>
                                                    Choose File...
                                                </MKButton>
                                            </Grid>
                                            <Grid item alignContent="center">
                                                {findMatchingBannerSizes(size, formData?.banners)?.length > 0 ? (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        label="File Name"
                                                        value={findMatchingBannerSizes(size, formData?.banners)}
                                                        InputProps={{
                                                            readOnly: true,
                                                            onClick: () => document.getElementById(`upload-html-${size.id}`).click(),
                                                        }}
                                                    />
                                                ) : (
                                                    <MKTypography variant="body1" color="secondary"
                                                        sx={{ fontSize: 14 }}> No file chosen</MKTypography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <MKBox width="100%" sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingBottom: "10px",
                    justifyContent: "flex-end"
                }}>
                    <Grid item xs={12} md={action === "edit" ? 2 : 3}>
                        <MKButton
                            fullWidth
                            color="primary"
                            type="submit"
                            endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            style={{ marginTop: '20px' }}
                            disabled={isLoading || formData?.banners?.length === 0}
                        >
                            {isLoading ? loadingButtonTitle : buttonTitle}
                        </MKButton>
                    </Grid>
                </MKBox>
            </Grid>
        </MKBox>
    );
};

export default TemplateSuitesForm;