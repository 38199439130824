import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CircularProgress, Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import TemplateSuitesForm from "../Components/TemplateSuitesForm";
import RestClient from "services/RestClient";
import { showToastMessage } from "../../../helpers/toastHelper";

function TemplateSuitesEditPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [templateSuiteData, setTemplateSuiteData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleTemplateSuiteUpdate = async (formData) => {
        setIsUpdating(true);
        const result = await RestClient.put(`/api/template_suites/${id}`, formData);
        if (result?.status === 200) {
            navigate("/template-suites");
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsUpdating(false);
    };
    const fetchTemplateSuite = async () => {
        setIsLoading(true);
        const result = await RestClient.get(`/api/template_suites/${id}`);
        if (result?.status === 200) {
            if (result.data) {
                setTemplateSuiteData(result.data[0]);
            }
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (id) {
            fetchTemplateSuite();
        }
    }, [id]);
    return (
        <Container sx={{ mt: 6, mb: 6 }}>
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
                <Breadcrumbs routes={[
                    { label: "Home", route: "/campaigns" },
                    { label: "Template Suites", route: "/template-suites" },
                    { label: "Edit Template Suite" },
                ]} />
            </MKBox>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    Loading... <CircularProgress />
                </div>
            ) : (
                <>
                    <MKTypography variant="h3" sx={{ mb: 3, mt: 2 }}>
                        {templateSuiteData?.name}
                    </MKTypography>
                    <TemplateSuitesForm
                        action={"edit"}
                        initialValues={templateSuiteData}
                        onSubmit={handleTemplateSuiteUpdate}
                        loadingButtonTitle={"Updating..."}
                        buttonTitle={"Update"}
                        isLoading={isUpdating}
                    />
                </>
            )}

        </Container>
    );
}

export default TemplateSuitesEditPage;