import React, { useState, useEffect } from 'react';

import Modal from "@mui/material/Modal"
import MKButton from 'components/MKButton';
import { Card, CardHeader } from '@mui/material'
import DealerForm from './DealerDetailForm';
const empty_dealer = {
	name: '',
	url: '',
	city: '',
	contact: '',
	workflow: ''
}
function DealerCreate({ dealer = empty_dealer, readOnly = false, onCreateNew = null }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(readOnly);
	const [formData, setFormData] = useState(dealer);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 800,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	const onCreate = (e) => {
		setIsModalOpen(false);
		if (onCreateNew)
			onCreateNew();

	}
	return (
		<div>
			<MKButton onClick={() => setIsModalOpen(true)} color="secondary">Add New Dealer</MKButton>

			{isModalOpen && (
				<Modal open={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Card sx={style}>
						<CardHeader title="Add New Dealer" bgColor="grey-100" />
						<DealerForm dealer={dealer} readOnly={false} onCreate={onCreate} />
					</Card>
				</Modal>
			)}
		</div>
	);
}

export default DealerCreate;