import React from 'react';
import { Delete, Visibility } from '@mui/icons-material';
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import DataGridTable from "../../../components/Table/DataGridTable"
import MKBox from 'components/MKBox';
import { useRole } from "context/RoleContext";

const UserManagementTable = ({ loadingTable, tableData, handleOpenUserDeleteModal, handleOpenUserEditModal }) => {
    const { roles } = useRole();
    const getRowId = row => {
        return row?.id;
    }
    const columns = [
        {
            field: 'name',
            headerName: 'Full Name',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 0.9,
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            minWidth: 150,
        },
        {
            field: 'actions',
            sortable: false,
            headerName: 'Actions',
            flex: 0.2,
            minWidth: 140,
            renderCell: (params) => {
                const disabledDelete = !roles.includes("Super-Admin") &&
                    ((params.row?.group === "Admin") || (params.row?.group === "Super-Admin"));
                return (
                    <strong>
                        <Tooltip title="View">
                            <IconButton onClick={() => handleOpenUserEditModal(params.row)}>
                                <Visibility color="info" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span>
                                <IconButton
                                    disabled={disabledDelete} onClick={() => handleOpenUserDeleteModal(params.row?.id)}>
                                    <Delete color={disabledDelete ? "secondary" : "error"} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </strong >
                )
            },
        },
    ];
    return (
        <>
            {loadingTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    Users are loading... <CircularProgress />
                </div>
            ) : (
                <MKBox sx={{ minHeight: 500, width: '100%' }}>
                    <DataGridTable
                        autoHeight
                        rows={tableData}
                        getRowId={getRowId}
                        columns={columns}
                        showQuickFilter={true}
                    />
                </MKBox>
            )}
        </>
    );
};

export default UserManagementTable;