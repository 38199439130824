import React, { useState, useEffect } from 'react';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import { Dialog, DialogContent } from '@mui/material'
import CampaignCreateModalForm from './CampaignCreateModalForm';

function CampaignCreate({ dealer, onComplete }) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const open = (e) => {
		setIsModalOpen(true);
	}

	const handleNewCampaign = async (e) => {
		setIsModalOpen(false);
		onComplete();
	}

	return (
		<>
			<MKButton
				onClick={open} color="primary">Add Campaign</MKButton>
			<Dialog fullWidth maxWidth="md"
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}

			>
				<DialogContent sx={{ p: 3.5 }}>
					<MKTypography variant="h5" sx={{ p: 3, pb: 1 }}>Request New Campaign</MKTypography>
					<CampaignCreateModalForm dealer={dealer} onCreate={handleNewCampaign} />
				</DialogContent>
			</Dialog>
		</>
	);
}

export default CampaignCreate;