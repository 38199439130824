import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import MKButton from 'components/MKButton';
import { UploadFile, Visibility, Cancel } from '@mui/icons-material';
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import DataGridTable from "../../../components/Table/DataGridTable"
import RestClient from 'services/RestClient';
import MKBox from 'components/MKBox';
import { showToastMessage } from '../../../helpers/toastHelper';

const TemplateSuitesTable = ({ loadingTable, tableData, updateTable }) => {
    const [downloadingMap, setDownloadingMap] = useState({});

    const downloadBanners = async (row) => {
        setDownloadingMap(prevState => ({
          ...prevState,
          [row.id]: { ...prevState[row.id], download: true },
        }));
        let templateName = row.name || 'Unknown_Dealer';
        templateName = templateName.replace(/\s+/g, '_');
        const currentDate = new Date().toISOString().slice(0, 10);
        const fileName = `${templateName}_${currentDate}_banners.zip`;
        const blob = await RestClient.getBlob(`/api/template_suites/download/${row.id}`);
        if (blob && !blob.message) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else if (blob?.status !== 200) {
          showToastMessage(blob?.message, "error");
        }
        setDownloadingMap(prevState => ({
          ...prevState,
          [row.id]: { ...prevState[row.id], download: false },
        }));
    };

    const getRowId = row => {
        return row?.id;
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Template Suite Name',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'actions',
            sortable: false,
            headerName: 'Actions',
            flex: 0.2,
            minWidth: 140,
            renderCell: (params) => (
                <strong>
                    <Tooltip title="View">
                        <IconButton component={Link} to={`/template-suites/edit/${params.row.id}`}>
                            <Visibility color="info" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                        <IconButton disabled={downloadingMap[params.row.id]?.download} onClick={() => downloadBanners(params.row)}>
                            {downloadingMap[params.row.id]?.download ? (
                                <CircularProgress color="secondary" size={18} />
                            ) : (
                                <UploadFile color="secondary" />
                            )}
                        </IconButton>
                    </Tooltip>
                </strong >
            ),
        },
    ];
    return (
        <>
            {loadingTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    Template Suites are loading... <CircularProgress />
                </div>
            ) : (
                <MKBox sx={{ minHeight: 500, width: '100%' }}>
                    <DataGridTable
                        autoHeight
                        rows={tableData}
                        getRowId={getRowId}
                        columns={columns}
                        showQuickFilter={true}
                    />
                </MKBox>
            )}
        </>
    );
};

export default TemplateSuitesTable;