export const validateQueryString = (value) => {
  const queryStringPattern = /^(?:\?|&)?(?:[a-zA-Z0-9_]+=[a-zA-Z0-9_%+-]+)(?:&[a-zA-Z0-9_]+=[a-zA-Z0-9_%+-]+)*$/;
  return queryStringPattern.test(value);
};

export const findMatchingBannerSizes = (singleObj, arr) => {
  const matchingNames = [];

  for (let obj of arr) {
    if (singleObj.width === obj.width && singleObj.height === obj.height) {
      matchingNames.push(obj.name);
    }
  }

  return matchingNames;
}

export const validateVariableInput = (value, type) => {
  if (type === 1) {
    const invalidChars = /^[a-zA-Z0-9\s.,!?;:'"()\-]*$/;
    return invalidChars.test(value);
  } else if (type === 2) {
    const urlPattern = /^(https?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    return urlPattern.test(value);
  }
  return true;
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};