import React, { useState, useEffect } from 'react';
import { Grid, Autocomplete, Switch, CircularProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ConfirmationModal from 'components/ConfirmationModal';
import { useRole } from "context/RoleContext";
import { showToastMessage } from "helpers/toastHelper";
import RestClient from "services/RestClient";

function UsersFormModal({ handleCloseUsersModal, openUserModal, initialData, onSubmit, isLoading, action, getUsersList }) {
    const { roles } = useRole();
    const superAdminRoleOptions = [
        { label: "Super-Admin", value: "Super-Admin" },
        { label: "Admin", value: "Admin" },
        { label: "Kijiji Sales", value: "KijijiSales" },
        { label: "Kijiji AdOps", value: "KijijiAdOps" },
    ];
    const adminRoleOptions = [
        { label: "Kijiji Sales", value: "KijijiSales" },
        { label: "Kijiji AdOps", value: "KijijiAdOps" },
    ];

    const roleOptions = roles.includes("Super-Admin") ? superAdminRoleOptions : adminRoleOptions;
    const [isDisablingUser, setIsDisablingUser] = useState(false);
    const [isSendingInvite, setIsSendingInvite] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        group: null,
    });
    const isDisabled = action === "edit" &&
        !roles.includes("Super-Admin") &&
        !roleOptions.find((role) => role.value === formData.group) &&
        formData.group;
    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[500],
            '&:hover': {
                backgroundColor: alpha(green[500], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: `${green[500]} !important`,
            borderColor: `${green[500]} !important`,
        },
    }));

    const handleClose = () => {
        handleCloseUsersModal();
        setFormData({
            name: '',
            email: '',
            group: null,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRoleChange = (event, value) => {
        setFormData({
            ...formData,
            group: value ? value.value : "",
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleDisableUser = async (event) => {
        setIsDisablingUser(true);
        setFormData({
            ...formData,
            status: event.target.checked,
        });
        const api = event.target.checked ? `/api/enable_user/${formData.id}` : `/api/disable_user/${formData.id}`;
        const result = await RestClient.put(api, {});
        if (result?.status === 200) {
            showToastMessage("User status has been successfully changed", "success");
            getUsersList();
            handleClose();
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsDisablingUser(false);
    };

    const handleResendInvite = async () => {
        setIsSendingInvite(true);
        const result = await RestClient.get(`/api/resend_invite/${formData?.id}`);
        if (result?.status === 200) {
            showToastMessage("Invite has been successfully sent", "success");
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsSendingInvite(false);
    };

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        }
    }, [initialData, openUserModal]);

    return (
        <>

            <ConfirmationModal
                openModal={openUserModal}
                modalTitle={action === "create" ? "Add New User" : "Edit User"}
                handleCloseModal={handleClose}
                isLoading={isLoading}
                disableAction={!(formData.name && formData.email && formData.group) || isDisabled}
                handleAction={handleSubmit}
                actionButtonTitle={action === "create" ? "Add User" : "Update"}
                actionButtonTitleLoading={action === "create" ? "Adding User..." : "Updating User..."}
                modalContent={
                    <>

                        <Grid spacing={2} container component="form" autoComplete="off" onSubmit={handleSubmit}>
                            {initialData && (
                                <Grid item xs={12} md={12}>
                                    <MKTypography sx={{
                                        fontSize: 13,
                                    }} variant="body1" color="secondary">User ID: {formData?.id}</MKTypography>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <MKInput required variant="standard" label="Full Name" name="name"
                                    placeholder="Enter Full Name" fullWidth rows={6}
                                    value={formData.name}
                                    disabled={isDisabled}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKInput required variant="standard" label="Email" name="email"
                                    placeholder="Enter Email" fullWidth rows={6}
                                    value={formData.email}
                                    disabled={isDisabled}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {isDisabled ? (
                                    <MKInput required variant="standard" label="User Role" disabled
                                        fullWidth rows={6}
                                        value={formData?.group || ""}
                                    />
                                ) : (
                                    <Autocomplete
                                        options={roleOptions}
                                        getOptionLabel={option => option.label}
                                        value={roleOptions.find((role) => role.value === formData.group) || null}
                                        onChange={handleRoleChange}
                                        renderInput={params => (
                                            <MKInput
                                                {...params}
                                                label="User Role"
                                                variant="standard"
                                                placeholder="Select User Role"
                                                required
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                            {initialData && (
                                <>
                                    <Grid item xs={12} md={6}>
                                        {isDisablingUser ? (<>
                                            Changing status...<CircularProgress color="secondary" size={18} />
                                        </>
                                        ) : (
                                            <>
                                                User Enabled <GreenSwitch
                                                    checked={(formData?.status === "Active") ? true : false}
                                                    disabled={isDisabled}
                                                    onChange={handleDisableUser}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MKButton
                                            color="secondary"
                                            variant="outlined"
                                            fullWidth
                                            endIcon={isSendingInvite ? <CircularProgress color="inherit" size={20} /> : null}
                                            disabled={isSendingInvite || isDisabled}
                                            onClick={handleResendInvite}
                                        >
                                            {isSendingInvite ? "Resending..." : "Resend Invite"}
                                        </MKButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </>
                }
            />
        </>
    );
}
export default UsersFormModal;