import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Papa from "papaparse";
import MKButton from 'components/MKButton';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import RestClient from 'services/RestClient';
import { showToastMessage } from 'helpers/toastHelper';
import ConfirmationModal from 'components/ConfirmationModal';
import DataGridTable from "components/Table/DataGridTable"
import { useRole } from "context/RoleContext";

function UsersUploadModal({ handleCloseUploadUsersModal, openUploadUsersModal, getUsersList }) {
    const { roles } = useRole();
    const [openUsersPreviewModal, setOpenUsersPreviewModal] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [base64CSV, setBase64CSV] = useState(null);
    const [usersPreviewData, setUsersPreviewData] = useState([]);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [isUploadingUser, setUploadingUser] = useState(false);

    const getRowId = row => {
        return row?.id;
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Full Name',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 0.7,
            minWidth: 150,
        }
    ];

    const handleUploadUsers = async () => {
        setUploadingUser(true);
        const result = await RestClient.post(`/api/upload_users`, { users: base64CSV });
        if (result?.status === 200) {
            showToastMessage("Users have been successfully created", "success");
            handleCloseUsersPreview();
            handleCloseUploadMenu();
            getUsersList();
        } else {
            showToastMessage(result?.message, "error");
        }
        setUploadingUser(false);
    };

    const handleCloseUploadMenu = () => {
        handleCloseUploadUsersModal();
        setCsvFile(null);
    };

    const handleOpenUsersPreview = () => {
        setIsLoadingPreview(true);
        if (csvFile) {
            Papa.parse(csvFile, {
                header: true,
                complete: (res) => {
                    const containsAdminEntries = res.data.some(
                        entry => entry.role === "Admin" || entry.role === "Super-Admin"
                    );
                    if (containsAdminEntries && !roles.includes("Super-Admin")) {
                        showToastMessage("Upload failed: only Super-Admin can upload Admin or Super-Admin users", "error");
                    } else {
                        setUsersPreviewData(res.data);
                        setOpenUsersPreviewModal(true);
                    }

                }
            })
        }
        setIsLoadingPreview(false);
    };

    const handleCloseUsersPreview = () => {
        setOpenUsersPreviewModal(false);
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleUploadCSV = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setCsvFile(file);
            try {
                const base64 = await convertFileToBase64(file);
                const base64Str = base64.split(',')[1];
                setBase64CSV(base64Str);
            } catch (error) {
                console.error('Error converting file to base64:', error);
            }
        }
    }

    return (
        <>

            <ConfirmationModal
                openModal={openUploadUsersModal}
                modalTitle="Upload User List"
                handleCloseModal={handleCloseUploadMenu}
                isLoading={isLoadingPreview}
                disableAction={!csvFile}
                handleAction={handleOpenUsersPreview}
                actionButtonTitle="Upload"
                actionButtonTitleLoading="Uploading..."
                modalContent={
                    <>
                        <Grid flexDirection="row" spacing={2} container sx={{ mt: 0.5 }}>
                            <Grid item alignContent="center">
                                <input
                                    id={`upload-csv`}
                                    type="file"
                                    onChange={(e) => handleUploadCSV(e)}
                                    style={{ display: 'none' }}
                                />
                                <MKButton
                                    variant="outlined" color="secondary" onClick={() => document.getElementById(`upload-csv`).click()}>
                                    Choose File...
                                </MKButton>
                            </Grid>
                            <Grid item alignContent="center">
                                {csvFile?.name ? (
                                    <MKTypography variant="body1" color="secondary"
                                        sx={{ fontSize: 14 }}>{csvFile.name}</MKTypography>
                                ) : (
                                    <MKTypography variant="body1" color="secondary"
                                        sx={{ fontSize: 14 }}>No file chosen</MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                }
            />
            <ConfirmationModal
                openModal={openUsersPreviewModal}
                modalTitle=""
                handleCloseModal={handleCloseUsersPreview}
                isLoading={isUploadingUser}
                disableAction={!csvFile}
                handleAction={() => handleUploadUsers()}
                actionButtonTitle="Add Users"
                actionButtonTitleLoading="Adding Users..."
                maxWidth="lg"
                modalContent={
                    <>
                        <MKTypography variant="body1" sx={{ fontSize: 16, mb: 2 }}>
                            Passwords will be generated for the following users and email invitations will be sent out
                        </MKTypography>
                        <MKBox sx={{ minHeight: 200, width: '100%' }}>
                            <DataGridTable
                                autoHeight
                                rows={usersPreviewData.map((row, index) => ({ id: index, ...row }))}
                                getRowId={getRowId}
                                columns={columns}
                                showQuickFilter={false}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 5
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                            />
                        </MKBox>
                    </>
                }
            />
        </>
    );
}
export default UsersUploadModal;