import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';
import { DeleteOutline } from '@mui/icons-material';
import RestClient from 'services/RestClient';
import { showToastMessage } from "../../helpers/toastHelper";
import MKInput from 'components/MKInput';

function DeleteCampaignModal({ campaignID }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleClickOpen = () => {
        setInputValue("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteCampaign = async () => {
        setIsDeleting(true);
        const result = await RestClient.delete(`/api/campaigns`, { ids: [campaignID] });
        if (result?.status === 200) {
            showToastMessage("Campaign has been successfully deleted", "success");
            navigate("/campaigns");
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsDeleting(false);

        handleClose();
    };

    useEffect(() => {
        setIsDisabled(true);
        if (inputValue === 'Delete') {
            setIsDisabled(false);
        }
    }, [inputValue]);

    return (
        <>
            <MKButton variant="outlined" color="error" endIcon={<DeleteOutline />} onClick={handleClickOpen}>
                Delete Campaign
            </MKButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete Campaign</DialogTitle>
                <DialogContent
                    style={{ marginBottom: '0' }}
                >
                    <p>Are you sure you want to delete this campaign?</p>
                    <p>Please type "Delete" to proceed:</p>
                    <MKInput
                        variant="standard"
                        required
                        label=""
                        sx={{ marginTop: 1 }}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        inputProps={{ maxLength: 6 }}
                    />
                </DialogContent>
                <DialogActions>
                    <MKButton onClick={handleClose}>Cancel</MKButton>
                    <MKButton endIcon={isDeleting ? <CircularProgress color="inherit" size={20} /> : <DeleteOutline />} color="error" disabled={isDeleting || isDisabled} onClick={handleDeleteCampaign}>
                        {isDeleting ? "Deleting..." : "Delete"}
                    </MKButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default DeleteCampaignModal;