import React, { Fragment, useState, useEffect } from "react";
import { Button, Grid, TextField, IconButton, CircularProgress, Autocomplete } from "@mui/material";
import { Delete, AddCircleOutline } from "@mui/icons-material";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import RestClient from "services/RestClient";
import { showToastMessage } from "../../../helpers/toastHelper";
import { convertFileToBase64, validateVariableInput } from "../../../helpers/helpers";
import ColorPickerMenu from "components/ColorPickerMenu";

const TemplateVariablesForm = ({ updateFormData, rows, setRows, editable }) => {
    const [loadingOptions, setIsLoadingOptions] = useState(false);
    const [variableOptions, setVariableOptions] = useState([]);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), variable_name: "", custom_variables_type_id: null, variable_value: "" }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleChange = (id, field, value) => {
        const updatedRows = rows.map(row => row.id === id ? { ...row, [field]: value } : row);
        setRows(updatedRows);
        updateFormData(updatedRows);
    };

    const validateFile = (file) => {
        const maxSize = 150 * 1024; // 150 KB
        const minSize = 1 * 1024; // 1 KB
        const maxFileNameLength = 255;
        const fileNameRegex = /^[a-zA-Z0-9._-]+$/;

        if (file.size > maxSize) {
            showToastMessage("File size exceeds the maximum limit of 150 KB", "error");
            return false;
        }
        if (file.size < minSize) {
            showToastMessage("File size is smaller than the minimum limit of 1 KB", "error");
            return false;
        }
        if (file.name.length > maxFileNameLength) {
            showToastMessage("File name exceeds the maximum limit of 255 characters", "error");
            return false;
        }
        if (!fileNameRegex.test(file.name)) {
            showToastMessage("File name should contain only alphanumeric characters, dashes, underscores, and dots", "error");
            return false;
        }
        return true;
    };

    const validateImageDimensions = (file) => {
        const maxWidth = 970;
        const maxHeight = 600;

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width > maxWidth || img.height > maxHeight) {
                    showToastMessage(
                        `Image dimensions exceed the maximum limit of ${maxWidth}x${maxHeight} pixels`,
                        "error"
                    );
                    reject(false);
                } else {
                    resolve(true);
                }
            };
            img.onerror = () => {
                showToastMessage("Invalid image file", "error");
                reject(false);
            };
        });
    };

    const handleUploadImg = async (e, id) => {
        const file = e.target.files[0];
        if (file && validateFile(file)) {
            try {
                const isValidDimensions = await validateImageDimensions(file);
                if (isValidDimensions) {
                    const base64 = await convertFileToBase64(file);
                    const base64Str = base64.split(",")[1];
                    const fileName = file.name;
                    const fileExtension = fileName.split(".").pop().toLowerCase();
                    const updatedRows = rows.map(
                        row => row.id === id ?
                            {
                                ...row, variable_value: base64Str,
                                extension: fileExtension,
                                file_name: fileName
                            } : row);
                    setRows(updatedRows);
                    updateFormData(updatedRows);
                }

            } catch (error) {
                console.error("Error converting file to base64:", error);
            }
        }
    }
    const getVariableOptions = async () => {
        setIsLoadingOptions(true);
        const result = await RestClient.get(`/api/custom_variables_types`);
        if (result?.status === 200) {
            const options = result?.data?.map(item => ({
                id: item.id,
                label: item.label
            }))
            setVariableOptions(options);
        } else {
            showToastMessage(result?.message, "error");
        }
        setIsLoadingOptions(false);
    };

    useEffect(() => {
        if (editable) {
            getVariableOptions();
        }
    }, []);

    return (
        <>
            {editable &&
                <Grid item xs={12} md={12}>
                    <MKBox sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "row",
                    }}>
                        <IconButton onClick={handleAddRow}>
                            <AddCircleOutline
                                fontSize="large"
                            />
                        </IconButton>
                        <MKTypography variant="h5">Add Campaign Variable</MKTypography>
                    </MKBox>
                </Grid>
            }
            {rows.map((row) => (
                <Fragment key={row.id}>
                    {editable ? (
                        <>
                            <Grid item xs={12} md={3}>
                                <MKBox sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "end",
                                }}>
                                    <IconButton onClick={() => handleRemoveRow(row.id)}>
                                        <Delete
                                            color="error"
                                        />
                                    </IconButton>
                                    <MKInput
                                        variant="standard"
                                        required
                                        fullWidth
                                        label="Variable Name"
                                        value={row.variable_name ? row.variable_name : ""}
                                        onChange={(e) => handleChange(row.id, "variable_name", e.target.value)}
                                        inputProps={{
                                            maxLength: 255
                                        }}
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    options={variableOptions}
                                    fullWidth
                                    disableClearable
                                    getOptionLabel={option => option.label || ""}
                                    value={variableOptions.find(option => option.id === row?.custom_variables_type_id) || null}
                                    onChange={(e, newValue) => handleChange(row.id, "custom_variables_type_id", newValue ? newValue.id : null)}
                                    renderInput={params => (
                                        <MKInput
                                            {...params}
                                            label="Variable Type"
                                            variant="standard"
                                            placeholder="Select Variable Type"
                                            required
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} md={5}>
                            <MKInput
                                variant="standard"
                                disabled
                                fullWidth
                                label="Variable Name"
                                value={row.variable_name ? row.variable_name : ""}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={editable ? 5 : 7}>
                        {row.custom_variables_type_id && row.custom_variables_type_id === 1 ? (
                            <MKInput
                                variant="standard"
                                fullWidth
                                label="Value"
                                value={row.variable_value ? row.variable_value : ""}
                                onChange={(e) => handleChange(row.id, "variable_value", e.target.value)}
                                inputProps={{
                                    maxLength: 255
                                }}
                                error={!validateVariableInput(row.variable_value, row.custom_variables_type_id)}
                                helperText={!validateVariableInput(row.variable_value, row.custom_variables_type_id) ? "Invalid characters: @, #, $, %, \\n, \\r, \\t" : ""}
                            />
                        ) : row.custom_variables_type_id && row.custom_variables_type_id === 2 ? (
                            <MKInput
                                variant="standard"
                                fullWidth
                                label="Value"
                                value={row.variable_value ? row.variable_value : ""}
                                onChange={(e) => handleChange(row.id, "variable_value", e.target.value)}
                                error={!validateVariableInput(row.variable_value, row.custom_variables_type_id)}
                                helperText={!validateVariableInput(row.variable_value, row.custom_variables_type_id) ? "Must be a valid URL starting with http or https" : ""}
                            />
                        ) : row.custom_variables_type_id && row.custom_variables_type_id === 3 ? (
                            <Grid flexDirection="row" spacing={2} container>
                                <Grid item alignContent="center">
                                    <input
                                        id={`upload-img-${row.id}`}
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .ico"
                                        onChange={(e) => handleUploadImg(e, row.id)}
                                        style={{ display: "none" }}
                                    />
                                    <MKButton
                                        variant="outlined" color="secondary" onClick={() => document.getElementById(`upload-img-${row.id}`).click()}>
                                        Choose File...
                                    </MKButton>
                                </Grid>
                                <Grid item alignContent="center">
                                    {row?.file_name || row?.variable_value ? (
                                        <MKInput
                                            variant="outlined"
                                            fullWidth
                                            label="File Name"
                                            value={row.file_name || row?.variable_value}
                                            InputProps={{
                                                readOnly: true,
                                                onClick: () => document.getElementById(`upload-img-${row.id}`).click(),
                                            }}
                                        />
                                    ) : (
                                        <MKTypography variant="body1" color="secondary"
                                            sx={{ fontSize: 14 }}>No file chosen</MKTypography>
                                    )}
                                </Grid>
                            </Grid>
                        ) : row.custom_variables_type_id && row.custom_variables_type_id === 4 ? (
                            <ColorPickerMenu onChangeComplete={handleChange} initialValue={row} />
                        ) : (
                            <MKInput
                                label="Default Value"
                                variant="standard"
                                fullWidth
                                value={row.variable_value ? row.variable_value : ""}
                                onChange={(e) => handleChange(row.id, "variable_value", e.target.value)}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />
                        )}
                    </Grid>
                </Fragment>
            ))}
        </>
    );
};

export default TemplateVariablesForm;