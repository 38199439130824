import React, { useState } from 'react';
import Modal from "@mui/material/Modal"
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import {Card, CardHeader} from '@mui/material'
import Grid from '@mui/material/Grid';
import {Switch} from '@mui/material';
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import MKDatePicker from 'components/MKDatePicker';
function Targetting(campaign) {
	const style = {
		
		width: 800,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	  };
  return (
    
      
			
				
				<Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
					<MKBox width="100%" component="form" method="post"  autoComplete="off">
						<MKBox p={3}>
						<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<MKTypography>Targetting form goes here</MKTypography>
						</Grid>
						</Grid>
						</MKBox>
					</MKBox>
				</Grid>
				
			
          
        
  );
}

export default Targetting;