import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import DealerCreate from "components/dealers/DealerCreateModal";
import SearchableTable from "components/SearchableTable";

import React, { useState, useEffect } from 'react';
import NavBar from "layouts/NavBar";
import RestClient from 'services/RestClient';

function DealersDashboardPage() {
  const [dealers, setDealers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const refreshDealers = async () => {
    const result = await RestClient.get('/api/dealers');
    if (result?.dealers) {
      setDealers(result.dealers);
    }
  }
  useEffect(() => {

    const fetchData = async () => {
      await refreshDealers();
      setIsLoading(false);
    };

    fetchData();
  }, []);
  const dealerAction = {
    "destination": "internal",
    "target": "/dealers/dealer/{id}",
    "keys": ["id"]
  };
  return (
    <Card
      sx={{
        p: 2,
        mx: { xs: 2, lg: 3 },
        mb: 4,
        mt: 4,
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
      }}
    >
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        <DealerCreate onCreateNew={refreshDealers} />
      </Stack>
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <SearchableTable action={dealerAction} data={dealers} />
      )}
    </Card>
  );
}

export default DealersDashboardPage;