import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Container, Autocomplete, Grid, CircularProgress } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import RestClient from "services/RestClient";
import DataGridTable from "components/Table/DataGridTable"
import Papa from "papaparse";
import { showToastMessage } from "helpers/toastHelper";

function ReportsPage() {
    const [dateOptions, setDateOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [isExportingToCsv, setIsExportingToCsv] = useState(false);
    const [reportPreviewData, setReportPreviewData] = useState([]);
    const [totalCountData, setTotalCountData] = useState({});
    const [isLoadingDateOptions, setIsLoadingDateOptions] = useState(false);

    const getRowId = row => {
        return row?.id;
    }

    const columns = [
        {
            field: "requested",
            headerName: "Request/Received Date",
            flex: 0.6,
            minWidth: 150,
        },
        {
            field: "sales_rep",
            headerName: "Requested by (Salesperson)",
            flex: 0.7,
            minWidth: 150,
        },
        {
            field: "dealer_id",
            headerName: "Dealer ID",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "dealer_name",
            headerName: "Dealer Name",
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: "start_date",
            headerName: "Campaign Start Date",
            flex: 0.6,
            minWidth: 150,
        },
        {
            field: "end_date",
            headerName: "Campaign End Date",
            flex: 0.6,
            minWidth: 150,
        },
        {
            field: "action",
            headerName: "New/Renew",
            flex: 0.2,
            minWidth: 150,
            renderCell: (params) =>
                params.row.action === "create" ? "N" : "R"
            ,
        },
    ];

    const generateMonthYearOptions = () => {
        setIsLoadingDateOptions(true);
        const options = [];
        const currentDate = new Date();
        for (let i = 0; i <= 6; i++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();
            options.push({ label: month + "-" + year, value: month + "-" + year });
        }
        setDateOptions(options);
        setSelectedDate(options[0]);
        setIsLoadingDateOptions(false);
    };

    const handleDateChange = (newValue) => {
        setReportPreviewData([]);
        if (newValue) {
            setSelectedDate(newValue);
        }
    };

    const getReportPreview = async () => {
        setIsLoadingPreview(true);
        const result = await RestClient.get(`/api/billing_report/${selectedDate?.label}`);
        if (result?.data) {
            setReportPreviewData(result.data.reportData);
            setTotalCountData(result.data.count);
            if (result.data.reportData?.length === 0) {
                showToastMessage("No data available", "error");
            }
        } else if (result?.status !== 200) {
            showToastMessage(result?.message, "error");
        }
        setIsLoadingPreview(false);
    };

    const formatDateForReportName = (prefix) => {
        const date = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}${String(date.getMonth() + 1).padStart(2, "0")}${day}`;
        return `${prefix}${month}_${year}_${formattedDate}.csv`;
    };

    const preprocessData = (data) => {
        return data.map(row => ({
            ...row,
            action: row.action === "create" ? "N" : "R"
        }));
    }

    const handleExportToCsv = async (data) => {
        setIsExportingToCsv(true);
        const processedData = preprocessData(data);
        let csv = Papa.unparse({
            fields: columns.map(col => col.headerName),
            data: processedData.map(row => columns.map(col => row[col.field])),
        })
        csv += `\nNew Campaigns,${totalCountData?.create}\nRenewals,${totalCountData?.renew}`
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const fileName = formatDateForReportName("Kijiji_Autos_Monthly_Billing_Report_");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsExportingToCsv(false);
    };

    useEffect(() => {
        generateMonthYearOptions();
    }, []);
    return (
        <Container sx={{ mt: 6, mb: 6 }}>
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
                <Breadcrumbs routes={[
                    { label: "Home", route: "/campaigns" },
                    { label: "Reports" },
                ]} />
            </MKBox>
            <MKTypography variant="h3" sx={{ mb: 3, mt: 2 }}>
                Monthly Billing Report
            </MKTypography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Autocomplete
                        options={dateOptions}
                        getOptionLabel={(option) => option.label}
                        value={selectedDate}
                        defaultValue={dateOptions[0]}
                        onChange={(event, newValue) => handleDateChange(newValue)}
                        disableClearable
                        renderInput={params => (
                            <MKInput
                                {...params}
                                label="Report Date"
                                variant="standard"
                                placeholder="Select Month-Year"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoadingDateOptions ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} alignContent="center">
                    <MKButton
                        color="secondary"
                        disabled={isLoadingPreview}
                        onClick={getReportPreview}>
                        {isLoadingPreview ? "Getting Report..." : "Get Report"}
                    </MKButton>
                </Grid>
                <Grid item xs={12} md={12}>
                    {reportPreviewData?.length > 0 && !isLoadingPreview && (
                        <>
                            <MKBox
                                sx={{ mb: 2 }}
                            >
                                <DataGridTable
                                    autoHeight
                                    rows={reportPreviewData}
                                    getRowId={getRowId}
                                    columns={columns}
                                    showQuickFilter={false}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                page: 0,
                                                pageSize: 5
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                />
                            </MKBox>
                            <MKBox sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                                <MKBox>
                                    <MKTypography variant="body2" >(This is the report preview)</MKTypography>
                                    <MKTypography variant="body2">New Campaigns: {totalCountData?.create ? totalCountData?.create : 0}</MKTypography>
                                    <MKTypography variant="body2">Renewals: {totalCountData?.renew ? totalCountData?.renew : 0}</MKTypography>
                                </MKBox>
                                <MKBox sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    paddingBottom: "10px",
                                    alignItems: "start"
                                }}>
                                    <MKButton
                                        color="primary"
                                        disabled={isExportingToCsv}
                                        onClick={() => handleExportToCsv(reportPreviewData)}>
                                        {isExportingToCsv ? "Exporting..." : "Export to CSV"}
                                    </MKButton>
                                </MKBox>
                            </MKBox>
                        </>
                    )}
                    {isLoadingPreview && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <CircularProgress />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

export default ReportsPage;