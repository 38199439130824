import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';
import MKInput from 'components/MKInput';
import { Update } from '@mui/icons-material';
import RestClient from 'services/RestClient';
import { showToastMessage } from '../../helpers/toastHelper';

function RenewCampaignModal({ startdate, campaignID, isCampaignExpired }) {
    const [open, setOpen] = useState(false);
    const [isRenewing, setIsRenewing] = useState(false);
    const [endDateError, setEndDateError] = useState("");
    const [endDate, setEndDate] = useState("");
    const currentDate = new Date().toISOString().replace(/T.*/, '')

    const handleClickOpen = () => {
        setEndDateError("");
        setEndDate("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRenewCampaign = async () => {
        setIsRenewing(true);
        const res = await RestClient.put(`/api/campaigns/campaign/${campaignID}/renew`, { enddate: endDate });
        if (res?.status === 200) {
            handleClose();
            setEndDate("");
            window.location.reload();
        } else {
            showToastMessage(res?.message, 'error');
        }
        setIsRenewing(false);
    };

    const handleEndDateChange = (e) => {
        setEndDateError("");
        if (e.target.value) {
            const isDateInvalid = (e.target.value <= startdate) || (e.target.value <= currentDate)
            if (isDateInvalid && (startdate !== "")) {
                setEndDate("");
                setEndDateError("Incorrect date");
            } else {
                setEndDate(e.target.value);
            }
        }
    };

    return (
        <>
            <MKButton 
            variant="outlined" 
            color="secondary" 
            endIcon={<Update />} 
            onClick={handleClickOpen}
            disabled={isCampaignExpired}
            >
                Renew
            </MKButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Renew Campaign</DialogTitle>
                <DialogContent
                    style={{ marginBottom: '0' }}
                >
                    <MKInput
                        variant="standard"
                        required
                        type="date"
                        label="New End Date"
                        sx={{ marginTop: 1 }}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={endDateError}
                        helperText={endDateError}
                        value={endDate}
                        onChange={handleEndDateChange} />
                </DialogContent>
                <DialogActions>
                    <MKButton onClick={handleClose}>Cancel</MKButton>
                    <MKButton endIcon={isRenewing ? <CircularProgress color="inherit" size={20} /> : ""} color="primary" disabled={!endDate || isRenewing} onClick={handleRenewCampaign}>
                        {isRenewing ? "Renewing..." : "Renew Campaign"}
                    </MKButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default RenewCampaignModal;