import React, { useState, useEffect } from "react";

import { Card, Grid, CircularProgress, Container } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import NavBar from "layouts/NavBar";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Breadcrumbs from "examples/Breadcrumbs";
import CampaignsDashboardTable from "./CampaignsDashboardTable";
import CampaignsDashboardMetrics from "./CampaignsDashboardMetrics";
import CampaignCreate from "components/campaigns/CampaignCreateModal";
import RestClient from "services/RestClient";
import { showToastMessage } from "../../helpers/toastHelper";
import { useRole } from "../../context/RoleContext";

function CampaignsDashboardPage() {
  const { roles } = useRole();
  const [dealerMetricsCount, setDealerMetricsCount] = useState(0);
  const [campaignMetricsCount, setCampaignMetricsCount] = useState(0);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [isDownloadingPerformanceReport, setIsDownloadingPerformanceReport] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableData, setTableData] = useState([]);


  const fetchMetricsData = async () => {
    setLoadingMetrics(true);
    const result = await RestClient.get(`/api/dashboard/metrics/general`);
    if (result?.data?.dealers) {
      setDealerMetricsCount(result.data.dealers);
      setCampaignMetricsCount(result.data.campaigns);
    } else if (result?.status !== 200) {
      showToastMessage(result?.message, "error");
    }
    setLoadingMetrics(false);
  };

  const fetchTableData = async () => {
    setLoadingTable(true);
    const result = await RestClient.get(`/api/campaigns/active`);
    if (result?.data && result?.data.length != 0) {
      setTableData(result.data);
    } else if (result?.status !== 200) {
      showToastMessage(result?.message, "error");
    }
    setLoadingTable(false);
  };

  const downloadPerformanceReport = async () => {
    setIsDownloadingPerformanceReport(true);
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `report_${currentDate}.xlsx`;
    const blob = await RestClient.getBlob(`/api/dashboard/metrics/report`);
    if (blob && !blob.message) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (blob?.status !== 200) {
      showToastMessage(blob?.message, "error");
    }
    setIsDownloadingPerformanceReport(false);
  };

  const updateDashboard = () => {
    fetchMetricsData();
    fetchTableData();
  }

  useEffect(() => {
    updateDashboard();
  }, []);

  return (
    <Container sx={{ mt: 6, mb: 6 }}>
      <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
        <Breadcrumbs routes={[
          { label: "Home", route: "/campaigns" },
          { label: "Campaigns" },
        ]} />
      </MKBox>
      <MKBox sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <CampaignsDashboardMetrics
          dealerCount={dealerMetricsCount}
          campaignCount={campaignMetricsCount}
          loadingMetrics={loadingMetrics}
        />
        <MKBox sx={{
          display: 'flex',
          alignItems: 'end',
          paddingBottom: "10px"
        }}>
          <MKButton
            sx={(roles.includes("Admin") || roles.includes("Super-Admin")) ? { marginRight: 1 } : null}
            color="secondary"
            disabled={isDownloadingPerformanceReport}
            endIcon={isDownloadingPerformanceReport ? <CircularProgress color="inherit" size={20} /> : <FileDownload />}
            onClick={downloadPerformanceReport}>
            {isDownloadingPerformanceReport ? "Exporting Report..." : "Performance Report"}
          </MKButton>
          {(roles.includes("Admin") || roles.includes("Super-Admin")) && (
            <CampaignCreate onComplete={updateDashboard} />
          )}
        </MKBox>
      </MKBox>

      <CampaignsDashboardTable
        tableData={tableData}
        loadingTable={loadingTable}
        updateDashboard={updateDashboard}
      />
    </Container>
  );
}

export default CampaignsDashboardPage;
