import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRole } from '../context/RoleContext';

const ProtectedRoute = ({ allowedRoles, element }) => {
    const { roles, isLoadingUserInfo } = useRole();
    if (!isLoadingUserInfo && roles?.length > 0) {
        console.log("current role:", roles)
        const hasAccess = roles.some(role => allowedRoles.includes(role));
        if (!hasAccess) {
            return <Navigate to="/campaigns" />;
        }
    }

    return element;
};

export default ProtectedRoute;