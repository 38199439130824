import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Modal, Typography, CircularProgress } from '@mui/material';
import EmailBannerForm from './EmailBannerForm';
import MKButton from 'components/MKButton';
import { Email } from '@mui/icons-material';
import RestClient from 'services/RestClient';

function EmailBannerModal({ campaignID }) {
    const defaultEmail = "srv.ecg.kijijiadops.eclassifiedsgroup.com@adevinta.com";
    const [open, setOpen] = useState(false);
    const [emailsList, setEmailsList] = useState([defaultEmail]);
    const [isSending, setIsSending] = useState(false);
    const [emailRecipientsMessage, setEmailRecipientsMessage] = useState("");

    const handleClickOpen = () => {
        setEmailRecipientsMessage("");
        setEmailsList([defaultEmail]);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSendEmail = async () => {
        if (emailsList.length === 0) {
            setEmailRecipientsMessage("Please add one or more email address");
            return;
        }
        setIsSending(true);
        await RestClient.post(`/api/campaign/${campaignID}/sendemail`, { recipients: emailsList });
        setIsSending(false);
        setEmailsList([defaultEmail]);
        handleClose();
    };

    const handleEmailsChange = (newEmails) => {
        setEmailsList(newEmails);
        setEmailRecipientsMessage("");
    };

    return (
        <>
            <MKButton variant="outlined" color="secondary" endIcon={<Email />} onClick={handleClickOpen}>
                Email Banners
            </MKButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Email Banners</DialogTitle>
                <DialogContent
                    style={{ marginBottom: '0' }}
                >
                    <EmailBannerForm emailsList={emailsList} handleEmailsChange={handleEmailsChange} emailRecipientsMessage={emailRecipientsMessage} />
                </DialogContent>
                <DialogActions>
                    <MKButton onClick={handleClose}>Cancel</MKButton>
                    <MKButton endIcon={isSending ? <CircularProgress color="inherit" size={20} /> : <Email />} color="primary" disabled={(emailsList?.length === 0) || isSending} onClick={handleSendEmail}>
                        {isSending ? "Sending..." : "Send"}
                    </MKButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default EmailBannerModal;