import React, { useState, useEffect } from 'react';

function BannerIFrame({ link }) {

  const extractDimensions = (link) => {
    const match = link.match(/\/(\d+)\/(\d+)x(\d+)\//);
    if (match) {
      const width = parseInt(match[2], 10) + 2;
      const height = parseInt(match[3], 10) + 2;
      return { width, height };
    }
    return { width: 0, height: 0 };
  };
  const { width, height } = extractDimensions(link);

  return (
    <iframe
      src={`${window.location.origin}${link}`}
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  );
}

export default BannerIFrame;