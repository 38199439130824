
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DealerInfo from "components/dealers/DealerInfo"
import RestClient from 'services/RestClient';
import Breadcrumbs from "examples/Breadcrumbs";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { CircularProgress, Container } from "@mui/material";

function DealerView({ dealer }) {
	const { id } = useParams();
	const [dealerData, setDealerData] = useState(dealer);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async () => {
		setIsLoading(true);
		const data = await RestClient.get(`/api/dealers/dealer/${id}`);
		if (data?.dealer) {
			setDealerData(data.dealer);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (id) {
			fetchData();
		}
	}, [id]);

	return (
		<Container sx={{ mt: 6, mb: 6 }}>
			{isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					Loading Dealer data... <CircularProgress />
				</div>
			) : (
				<>
					<MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
						<Breadcrumbs routes={[
							{ label: "Dealers", route: "/dealers" },
							{ label: "Dealer" },
						]} />
					</MKBox>
					<MKTypography variant="h3" mb={1}>
						{dealerData?.name}
					</MKTypography>
					<DealerInfo dealer={dealerData} />
				</>
			)}
		</Container>
	);
}

export default DealerView;