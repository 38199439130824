// Cancel Campaign is using the same endpoint as Pause until the corresponding backend feature will be delivered
// Publish Campaign requires integration with GAM

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import MKButton from 'components/MKButton';
import { UploadFile, Publish, Visibility, PauseCircleOutline, Cancel } from '@mui/icons-material';
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import DataGridTable from "../../components/Table/DataGridTable"
import RestClient from 'services/RestClient';
import MKBox from 'components/MKBox';
import { showToastMessage } from '../../helpers/toastHelper';
import { useRole } from "../../context/RoleContext";

const CampaignsDashboardTable = ({ loadingTable, tableData, updateDashboard }) => {
  const { roles } = useRole();
  const [downloadingMap, setDownloadingMap] = useState({});

  const getRowId = row => {
    return row?.Campaign;
  }

  const handlePauseCampaign = async (row) => {
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], pause: true },
    }));
    const result = await RestClient.put(`/api/campaigns/pause/${row.Campaign}`, {});
    if (result.status === 200) {
      updateDashboard();
    } else {
      showToastMessage(result?.message, "error");
    }
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], pause: false },
    }));
  }

  const handleCancelCampaign = async (row) => {
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], cancel: true },
    }));
    const result = await RestClient.put(`/api/campaigns/pause/${row.Campaign}`, {});
    if (result.status === 200) {
      updateDashboard();
    } else {
      showToastMessage(result?.message, "error");
    }
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], cancel: false },
    }));
  }

  const userColumns = [
    {
      field: 'Campaign',
      headerName: 'ID',
      flex: 0.3,
      minWidth: 70,
    },
    {
      field: 'Dealer',
      headerName: 'Dealer Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'SalesRep',
      headerName: 'Sales Rep',
      flex: 0.7,
      minWidth: 140,
    },
    {
      field: 'Start Date',
      headerName: 'Start',
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'End Date',
      headerName: 'Finish',
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'Views',
      headerName: 'Views',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'Clicks',
      headerName: 'Clicks',
      flex: 0.5,
      minWidth: 100,
    },
  ];

  const adminColumns = [
    {
      field: 'Campaign',
      headerName: 'ID',
      flex: 0.3,
      minWidth: 70,
    },
    {
      field: 'Dealer',
      headerName: 'Dealer Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'SalesRep',
      headerName: 'Sales Rep',
      flex: 0.7,
      minWidth: 140,
    },
    {
      field: 'Start Date',
      headerName: 'Start',
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'End Date',
      headerName: 'Finish',
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'Views',
      headerName: 'Views',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'Clicks',
      headerName: 'Clicks',
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'actions',
      sortable: false,
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 220,
      renderCell: (params) => (
        <strong>
          <Tooltip title="View">
            <IconButton component={Link} to={`/campaigns/campaign/${params.row.Campaign}`}>
              <Visibility color="info" />
            </IconButton>

          </Tooltip>
          <Tooltip title="Publish">
            <IconButton onClick={() => console.log("click")}>
              <Publish color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton disabled={downloadingMap[params.row.Campaign]?.download} onClick={() => downloadBanners(params.row)}>
              {downloadingMap[params.row.Campaign]?.download ? (
                <CircularProgress color="secondary" size={18} />
              ) : (
                <UploadFile color="secondary" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Pause">
            <IconButton disabled={downloadingMap[params.row.Campaign]?.pause} onClick={() => handlePauseCampaign(params.row)}>
              {downloadingMap[params.row.Campaign]?.pause ? (
                <CircularProgress color="warning" size={18} />
              ) : (
                <PauseCircleOutline color="warning" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton disabled={downloadingMap[params.row.Campaign]?.cancel} onClick={() => handleCancelCampaign(params.row)}>
              {downloadingMap[params.row.Campaign]?.cancel ? (
                <CircularProgress color="error" size={18} />
              ) : (
                <Cancel color="error" />
              )}
            </IconButton>
          </Tooltip>
        </strong >
      ),
    },
  ];

  const downloadBanners = async (row) => {
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], download: true },
    }));
    let dealerName = row.Dealer || 'Unknown_Dealer';
    dealerName = dealerName.replace(/\s+/g, '_');
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `${dealerName}_${currentDate}_banners.zip`;
    const blob = await RestClient.getBlob(`/api/campaigns/campaign/${row.Campaign}/download`);
    if (blob && !blob.message) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (blob?.status !== 200) {
      showToastMessage(blob?.message, "error");
    }
    setDownloadingMap(prevState => ({
      ...prevState,
      [row.Campaign]: { ...prevState[row.Campaign], download: false },
    }));
  };

  return (
    <>
      {loadingTable ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          Campaigns are loading... <CircularProgress />
        </div>
      ) : (
        <MKBox sx={{ minHeight: 500, width: '100%' }}>
          <DataGridTable
            autoHeight
            rows={tableData}
            getRowId={getRowId}
            columns={(roles.includes("Admin") || roles.includes("Super-Admin")) ? adminColumns : userColumns}
            showQuickFilter={true}
          />
        </MKBox>
      )}
    </>
  );
};

export default CampaignsDashboardTable;