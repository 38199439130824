import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link } from "react-router-dom";
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
function getMonth(num){
    const monthNames = [
        "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
    ]
    return monthNames[num-1]; // num is 1 based, array is 0 based
}
function CampaignsDetailBannerMetrics({ bannerMetricsData, campaignData }) {
    const [bannerSizes, setBannerSizes] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [monthlyData, setMonthlyData] = useState({})
    useEffect(() => {
        if (bannerMetricsData['size']) {
            setBannerSizes(Object.keys(bannerMetricsData['size']));
        }
        if(bannerMetricsData['monthly']){
            const months = {};
            for(const month of bannerMetricsData['monthly']){
                months[`${getMonth(month.month)}-${month.year}`] = month;
            }
            setMonthlyData(months)
        }
    }, [bannerMetricsData['size']]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }
    return (
        <Card>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="By Banner Size" {...a11yProps(0)} />
            <Tab label="By Month" {...a11yProps(1)} />
            
        </Tabs>
        <CustomTabPanel value={tabValue} index={0}>
        <TableContainer sx={{ my: 3, borderRadius: 1 }} >
            <Table>
                <TableHead sx={{
                    display: "table-header-group", "& th": {
                        color: "#1f2020",
                        backgroundColor: "#f0f2f5"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Banner Size
                        </TableCell>
                        <TableCell>
                            Views
                        </TableCell>
                        <TableCell>
                            Clicks
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bannerSizes?.length > 0 ? (
                        <>
                            {bannerSizes.map((size) => (
                                <TableRow hover>
                                    <TableCell>
                                        {size}
                                    </TableCell>
                                    <TableCell>
                                        {bannerMetricsData['size'][size]?.views}
                                    </TableCell>
                                    <TableCell>
                                        <Link style={{color: 'rgb(0, 102, 204)'}} to={`/report/${campaignData?.id}`} state={{ rowData: campaignData }}>
                                            {bannerMetricsData['size'][size]?.clicks}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell align='center' colSpan={3}>
                                No rows
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
        <TableContainer sx={{ my: 3, borderRadius: 1 }} value={tabValue} index={1}>
            <Table>
                <TableHead sx={{
                    display: "table-header-group", "& th": {
                        color: "#1f2020",
                        backgroundColor: "#f0f2f5"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Month
                        </TableCell>
                        <TableCell>
                            Views
                        </TableCell>
                        <TableCell>
                            Clicks
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bannerSizes?.length > 0 ? (
                        <>
                            {Object.keys(monthlyData).map((month) => (
                                <TableRow hover>
                                    <TableCell>
                                        {month}
                                    </TableCell>
                                    <TableCell>
                                        {monthlyData[month]?.impressions}
                                    </TableCell>
                                    <TableCell>
                                        <Link style={{color: 'rgb(0, 102, 204)'}} to={`/report/${campaignData?.id}`} state={{ rowData: campaignData }}>
                                            {monthlyData[month]?.clicks}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell align='center' colSpan={3}>
                                No rows
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
        <TableContainer sx={{ my: 3, borderRadius: 1 }} value={tabValue} index={2}>
            <Table>
                <TableHead sx={{
                    display: "table-header-group", "& th": {
                        color: "#1f2020",
                        backgroundColor: "#f0f2f5"
                    }
                }}>
                    <TableRow>
                        <TableCell>
                            Vehicle
                        </TableCell>
                        <TableCell>
                            Views
                        </TableCell>
                        <TableCell>
                            Clicks
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bannerSizes?.length > 0 ? (
                        <>
                            {bannerSizes.map((size) => (
                                <TableRow hover>
                                    <TableCell>
                                        {size}
                                    </TableCell>
                                    <TableCell>
                                        {bannerMetricsData[size]?.views}
                                    </TableCell>
                                    <TableCell>
                                        <Link style={{color: 'rgb(0, 102, 204)'}} to={`/report/${campaignData?.id}`} state={{ rowData: campaignData }}>
                                            {bannerMetricsData[size]?.clicks}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell align='center' colSpan={3}>
                                No rows
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        </CustomTabPanel>
        </Card>
        
    );
}

export default CampaignsDetailBannerMetrics;

/**
 * 
 */