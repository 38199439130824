import { Grid, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import MKBox from "components/MKBox";
import { Visibility } from '@mui/icons-material';
import DataGridTable from "../../components/Table/DataGridTable"
import DealerForm from "./DealerDetailForm";
import CampaignCreate from "components/campaigns/CampaignCreateModal";
import RestClient from 'services/RestClient';
import DeleteDealerModal from './DeleteDealerModal';
import { useRole } from "../../context/RoleContext";

function DealerInfo({ dealer }) {
  const { roles } = useRole();
  const [dealerData, setDealerData] = useState(dealer);
  const [dealerCampaigns, setDealerCampaigns] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [robotsLoading, setRobotsLoading] = useState(false);
  const [robotsList, setRobotsList] = useState([]);

  const columns = [
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 0.1,
      minWidth: 70,
      renderCell: (params) => (
        <strong>
          <Tooltip title="View">
            <IconButton component={Link} to={`/campaigns/campaign/${params.row.id}`}>
              <Visibility color="info" />
            </IconButton>
          </Tooltip>
        </strong >
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      minWidth: 55,
    },
    {
      field: 'requested',
      headerName: 'Requested',
      flex: 0.3,
      minWidth: 170,
    },
    {
      field: 'gam_campaign_id',
      headerName: 'GAM Campaign',
      flex: 0.1,
      minWidth: 135,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 0.2,
      minWidth: 70,
    },
    {
      field: 'startdate',
      headerName: 'Start',
      flex: 0.3,
      minWidth: 115,
    },
    {
      field: 'enddate',
      headerName: 'Finish',
      flex: 0.3,
      minWidth: 115,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.1,
      minWidth: 70,
    },
    {
      field: 'sales_rep',
      headerName: 'Sales Rep',
      flex: 0.3,
      minWidth: 95,
    },
    {
      field: 'template_suite_id',
      headerName: 'Template Suite ID',
      flex: 0.3,
      minWidth: 90,
    },
    {
      field: 'query_string',
      headerName: 'Query String',
      flex: 0.3,
      minWidth: 90,
    },
    {
      field: 'deleted',
      headerName: 'Deleted',
      flex: 0.2,
      minWidth: 80,
    },
  ];

  const fetchData = async () => {
    setLoadingTable(true);
    const data = await RestClient.get(`/api/dealers/${dealerData?.id}/campaigns`);
    if (data?.campaigns) {
      setDealerCampaigns(data.campaigns);
    }
    setLoadingTable(false);
  };

  const fetchRobotsList = async () => {
    setRobotsLoading(true);
    const data = await RestClient.get(`/api/robots/list`);
    if (data?.robots) {
      setRobotsList(data.robots);
    }
    setRobotsLoading(false);
  };

  useEffect(() => {
    if (dealer?.dealer) {
      setDealerData(dealer?.dealer);
    }
    fetchRobotsList();
  }, [dealer]);

  useEffect(() => {
    if (dealerData) {
      fetchData();
    }
  }, [dealerData]);

  return (
    <MKBox width="100%" >
      <MKBox p={3}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} md={6}>
            <img src={dealerData?.logo} style={{ maxHeight: "200px", maxWidth: "300px" }} />
          </Grid>
          {roles.includes("Super-Admin") && (
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <DeleteDealerModal dealerID={dealerData?.id} />
              </div>
            </Grid>
          )}
        </Grid>
        <DealerForm dealer={dealerData} robotsList={robotsList} robotsLoading={robotsLoading} />
        <CampaignCreate dealer={dealerData} onComplete={fetchData} />
        {loadingTable ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            Campaigns are loading... <CircularProgress />
          </div>
        ) : (
          <>
            <MKBox sx={{ minHeight: 300, width: '100%', mt: 2 }}>
              <DataGridTable
                autoHeight
                rows={dealerCampaigns}
                columns={columns}
                showQuickFilter={true}
              />
            </MKBox>
          </>
        )}
      </MKBox>
    </MKBox>
  );
}

export default DealerInfo;
