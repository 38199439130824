import React, { useState, useEffect } from 'react';

import MKButton from 'components/MKButton';
import { Switch } from '@mui/material';
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import { AppBar, Tabs, Tab, Grid, Autocomplete, CircularProgress } from '@mui/material';
import RestClient from 'services/RestClient';
import DeleteDealerModal from './DeleteDealerModal';

const empty_dealer = {
	name: '',
	url: '',
	city: '',
	contact: '',
	robot: '',
	mediaSource: 1
};
function DealerForm({ dealer = empty_dealer, readOnly, robotsList, robotsLoading, onCreate = null }) {
	const [activeTab, setActiveTab] = useState(1);
	const handleTabType = (event, newValue) => setActiveTab(newValue);
	const [file, setFile] = useState([]);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [formData, setFormData] = useState(dealer);
	const [robots, setRobots] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdatingDealer, setIsUpdatingDealer] = useState(false);
	const [saveColor, setSaveColor] = useState("dark");
	const [saveButtonText, setButtonText] = useState(dealer.name == "" ? "Add Dealer" : "Update")
	const [selectedPrimaryRobot, setSelectedPrimaryRobot] = useState(null);
	const [selectedSecondaryRobot, setSelectedSecondaryRobot] = useState(null);

	useEffect(() => {
		if (robotsList?.length > 0) {
			setRobots(robotsList);
		}
	}, [robotsList]);

	useEffect(() => {
		if (!robotsList) {
			const fetchData = async () => {
				setIsLoading(true);
				const data = await RestClient.get(`/api/robots/list`);
				if (data?.robots) {
					setRobots(data.robots);
				}
				setIsLoading(false);
			};
			fetchData();
		}
	}, []);

	const toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsUpdatingDealer(true);
		if (file.name) {
			formData.logo = await file.name;
			formData.logoImage = await toBase64(file);
		}

		formData.mediaSource = activeTab;
		const result = await RestClient.post('/api/dealers/persist', formData);

		if (result?.status === 200) {
			setButtonText("Dealer Saved - Retrieving Robot results");
			setSaveColor("success")
			const r2 = await RestClient.get(`/api/dealer/${formData.id}/campaigns/refreshresults`)
			setButtonText("Robot Results retrieved");
			if (dealer.name !== "") {
				window.location.reload();
			}
		} else {
			setButtonText("Error in Saving");
			setSaved("primary");
			console.error('Error creating dealer:', result?.message);
		}
		if (onCreate) {
			onCreate()
		}
		setIsUpdatingDealer(false);
	};

	const handlePrimaryRobotChange = (event, newValue) => {
		if (newValue !== null) {
			setSelectedPrimaryRobot(newValue);
			setFormData({ ...formData, robot: newValue.id })
		} else if (newValue === null) {
			setSelectedPrimaryRobot(newValue);
			setFormData({ ...formData, robot: "" })
		}
	}
	const handleSecondaryRobotChange = (event, newValue) => {
		if (newValue !== null) {
			setSelectedSecondaryRobot(newValue);
			setFormData({ ...formData, detail_robot: newValue.id })
		} else if (newValue === null) {
			setSelectedSecondaryRobot(newValue);
			setFormData({ ...formData, detail_robot: "" })
		}
	}

	// const runRobot = async () => {
	// 	try {
	// 		const response = await fetch(`/api/robots/robot/${formData.robot}/run`);
	// 		if (response.ok)
	// 			alert("Robot is running");

	// 		setRobots(data.robots);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }
	const addLogo = (e) => {
		setFile(e.target.files[0]);
		setFormData({ ...formData, logo: e.target.files[0].name })
	}

	useEffect(() => {
		if (formData.mingleId) {
			setActiveTab(2);
		}

	}, [formData.mingleId]);

	useEffect(() => {
		if (robots.length > 0) {
			if (formData.robot) {
				const defaultPrimaryRobot = robots.find(item => item.id === formData.robot)
				if (defaultPrimaryRobot) {
					setSelectedPrimaryRobot(defaultPrimaryRobot);
				}
			}
			if (formData.detail_robot) {
				const defaultSecondaryRobot = robots.find(item => item.id === formData.detail_robot)
				if (defaultSecondaryRobot) {
					setSelectedSecondaryRobot(defaultSecondaryRobot);
				}
			}
		}
	}, [robots]);

	return (
		<Grid container xs={12} lg={12}>
			<MKBox width="100%" component="form" method="post" onSubmit={handleSubmit} autoComplete="off">
				<MKBox sx={{mb: 2}}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<MKInput variant="standard" required disabled={false} label="Dealer Name" fullWidth value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
						</Grid>
						<Grid item xs={12} md={6}>
							<MKInput variant="standard" required disabled={isReadOnly} label="Website Url" fullWidth value={formData.url} onChange={(e) => setFormData({ ...formData, url: e.target.value })} />
						</Grid>
						<Grid item xs={12} md={6}>
							<MKInput variant="standard" required={dealer.name !== ""} disabled={isReadOnly} label="Dealer Logo" fullWidth value={formData.logo ? formData.logo : ""} onChange={(e) => setFormData({ ...formData, logo: e.target.value })} />
						</Grid>

						<Grid item xs={12} md={6}>
							<MKInput variant="standard" required disabled={isReadOnly} label="Contact Name" fullWidth rows={6} value={formData.contact} onChange={(e) => setFormData({ ...formData, contact: e.target.value })} />
						</Grid>
						<Grid item xs={12} md={6}>
							<MKInput
								type="file"
								id="upload-photo"
								name="upload-photo"
								onChange={addLogo}
								required={dealer.name === ""} />
						</Grid>

						<Grid item xs={12} lg={12}>
							<AppBar position="static">
								<Tabs value={activeTab} onChange={handleTabType}>
									<Tab
										icon={
											<MKBox
												component="i"
												color="dark"
												mr={1.25}
												sx={{ fontSize: ({ typography: { size } }) => size.sm }}
												className="fas fa-desktop"
											/>
										}
										label="Browse.ai"
										value={1}
										disabled={formData.mingleId}
									/>
									<Tab
										icon={
											<MKBox
												component="i"
												color="dark"
												mr={1.25}
												sx={{ fontSize: ({ typography: { size } }) => size.sm }}
												className="fas fa-code"
											/>
										}
										label="Kijiji API"
										value={2}
										disabled={formData.robot || formData.detail_robot}
									/>
								</Tabs>
							</AppBar>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MKBox width="100%" lg={6} xs={12} display={activeTab === 1 ? "block" : "none"}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<Autocomplete
											id="tags-standard"
											options={robots}
											getOptionLabel={(option) => option.name}
											onChange={handlePrimaryRobotChange}
											value={selectedPrimaryRobot}
											renderInput={(params) => (
												<MKInput
													{...params}
													variant="standard"
													label="Primary/List Robot"
													placeholder="Robot"
													required={activeTab === 1}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{isLoading || robotsLoading ? <CircularProgress color="inherit" size={20} /> : null}
																{params.InputProps.endAdornment}
															</>
														),
													}}
												/>
											)}
										/>
									</Grid>
									{/* <MKButton onClick={() => { refreshRobotList(); }}>Reset Robots</MKButton> */}
									<Grid item xs={12} md={6}>
										<Autocomplete
											id="tags-standard"
											options={robots}
											getOptionLabel={(option) => option.name}
											onChange={handleSecondaryRobotChange}
											value={selectedSecondaryRobot}
											renderInput={(params) => (
												<MKInput
													{...params}
													variant="standard"
													label="Detail Robot"
													placeholder="Robot"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{isLoading || robotsLoading ? <CircularProgress color="inherit" size={20} /> : null}
																{params.InputProps.endAdornment}
															</>
														),
													}}
												/>
												// 905 844 0181
											)}
										/>
									</Grid>
									{/* <Grid item xs={2}>
										<MKButton onClick={runRobot} variant="gradient" color="dark" fullWidth>Run Robot</MKButton>
									</Grid> */}
								</Grid>
							</MKBox>

							<MKBox width="100%" lg={6} xs={12} display={activeTab === 2 ? "block" : "none"}>
								<Grid container spacing={3}>
									<Grid item xs={6} lg={6}>
										<MKInput variant="standard" disabled={isReadOnly} label="Dealer ID" fullWidth rows={6} value={formData.mingleId}
											onChange={(e) => setFormData({ ...formData, mingleId: e.target.value })}
											required={activeTab === 2} />
									</Grid>
									<Grid item xs={6}>

									</Grid>
								</Grid>
							</MKBox>
						</Grid>
					</Grid>
					{isReadOnly ? (
						<></>
					) : (
						<Grid container justifyContent="center" sx={{ mt: 3 }}>
							<Grid item xs={12} md={12}>
								<MKButton type="submit" variant="gradient" disabled={isUpdatingDealer} color={saveColor} endIcon={isUpdatingDealer ? <CircularProgress color="inherit" size={20} /> : ""} fullWidth>
									{saveButtonText}
								</MKButton>
							</Grid>
						</Grid>
					)}
				</MKBox>
			</MKBox>
		</Grid>
	);
}

export default DealerForm;