import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const DataGridTable = ({
  rows,
  columns,
  getRowId,
  showQuickFilter,
  ...otherProps
}) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      rowsPerPageOptions={[5, 10, 20]}
      disableColumnSelector={true}
      disableRowSelectionOnClick={true}
      autoHeight={true}
      getRowId={getRowId}
      initialState={{
        pagination: {
          paginationModel: {
            page: 0,
            pageSize: 10
          },
        },
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: showQuickFilter,
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
      disableColumnFilter
      disableDensitySelector
      disableToolbarButton={true}
      pageSizeOptions={[10, 25, 50]}
      disableColumnMenu={true}
      {...otherProps}
    />
  );
};

export default DataGridTable;