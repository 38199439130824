class RestClient {
    constructor() {
        this.abortController = new AbortController();
    }

    async handleResponse(response) {
        if (response.status == 401) {
            if (response.headers.get('Location'))
                window.location.href = response.headers.get('Location');
            else
                window.location.href = '/login';
            return null;
        }
        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.includes('application/json')) {
            const result = await response.json();
            if (!response.ok) {
                if (result?.message) {
                    return {
                        status: result?.status,
                        message: result?.message,
                    };
                } else {
                    return {
                        status: result?.status,
                        message: 'The request could not be satisfied.',
                    };
                }
            }
            if (result?.loginRequired) {
                window.location.href = result?.Location;
            }
            return result;
        } else {
            console.error('The request could not be satisfied.');
        }
    }

    async fetchData(url, method, params) {
        try {
            const requestOptions = {
                method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: params ? JSON.stringify(params) : undefined,
                signal: this.abortController.signal,
            };

            const response = await fetch(url, requestOptions);
            return await this.handleResponse(response);
        } catch (e) {
            console.error(e);
        }
    }

    async getBlob(url) {
        try {
            const requestOptions = {
                method: 'GET',
                signal: this.abortController.signal,
            };
            const response = await fetch(url, requestOptions);
            if (response.status == 401) {
                if (response.headers.get('Location'))
                    window.location.href = response.headers.get('Location');
                else
                    window.location.href = '/login';
                return null;
            }
            if (!response.ok) {
                return {
                    message: 'The request could not be satisfied.',
                };
            }
            return await response.blob();
        } catch (e) {
            console.error(e);
        }
    }

    async get(url) {
        return await this.fetchData(url, 'GET');
    }

    async post(url, params) {
        return await this.fetchData(url, 'POST', params);
    }

    async put(url, params) {
        return await this.fetchData(url, 'PUT', params);
    }

    async delete(url, params) {
        return await this.fetchData(url, 'DELETE', params);
    }

    cancelRequest() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }
}

export default new RestClient();